const runners = [
  {
    _id: 220,
    runner1: "Elin Lundblad",
    runner2: "David Lundblad",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:21:52.343Z",
    officialtime: "00:20:33",
    runnersclass: "mixed",
  },
  {
    _id: 221,
    runner1: "Jessica  Herweijer",
    runner2: "Josefin Herweijer",
    race: "mellan",
    startgroup: 1,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 222,
    runner1: "Ingrid Kylén",
    runner2: "Ronja Kylén",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:20:39.015Z",
    officialtime: "00:19:20",
    runnersclass: "dam",
  },
  {
    _id: 223,
    runner1: "Emma Gustafsson ",
    runner2: "Malin Pilo",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:32:33.027Z",
    officialtime: "00:31:14",
    runnersclass: "dam",
  },
  {
    _id: 2,
    runner1: "Andreas  Gröning",
    runner2: "Lovisa Carlsson",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:42:35.083Z",
    officialtime: "00:42:12",
    runnersclass: "mixed",
  },
  {
    _id: 3,
    runner1: "Patrik Lundblad",
    runner2: "Tina Lundblad",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:51:28.118Z",
    officialtime: "00:51:05",
    runnersclass: "mixed",
  },
  {
    _id: 4,
    runner1: "Wilmer Guvander",
    runner2: "Ella Storm",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:50:01.045Z",
    officialtime: "00:49:38",
    runnersclass: "mixed",
  },
  {
    _id: 5,
    runner1: "Gunnar  Wide",
    runner2: "Malin Storm",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:44:14.265Z",
    officialtime: "00:43:52",
    runnersclass: "mixed",
  },
  {
    _id: 6,
    runner1: "Erik Davidsson",
    runner2: "Samuel Norlén",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:38:54.973Z",
    officialtime: "00:38:32",
    runnersclass: "herr",
  },
  {
    _id: 7,
    runner1: "Philip Zuhr",
    runner2: "Nils Widmark",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:43:36.245Z",
    officialtime: "00:43:14",
    runnersclass: "herr",
  },
  {
    _id: 8,
    runner1: "Alma Haknert ",
    runner2: "Peter Haknert",
    race: "lång",
    startgroup: 1,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 9,
    runner1: "Magnus Skogsberg",
    runner2: "Ludvig Skogsberg",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:52:57.513Z",
    officialtime: "00:52:35",
    runnersclass: "mixed",
  },
  {
    _id: 10,
    runner1: "Mia  Enmark ",
    runner2: "Kristin Abrahamsson",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:56:43.679Z",
    officialtime: "00:56:21",
    runnersclass: "dam",
  },
  {
    _id: 11,
    runner1: "Anton Enmark ",
    runner2: "Annida  Toresdotter",
    race: "lång",
    startgroup: 2,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 12,
    runner1: "Theodore Franzen",
    runner2: "Mikael Franzen",
    race: "lång",
    startgroup: 2,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "herr",
  },
  {
    _id: 13,
    runner1: "Tintin  Herweijer",
    runner2: "Nils Herweijer",
    race: "lång",
    startgroup: 2,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 14,
    runner1: "Lotta Björkman",
    runner2: "Amanda Hansson",
    race: "lång",
    startgroup: 2,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "dam",
  },
  {
    _id: 15,
    runner1: "Ulrika  Billkrantz ",
    runner2: "Åsa Prebensen",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:58:52.550Z",
    officialtime: "00:56:39",
    runnersclass: "dam",
  },
  {
    _id: 16,
    runner1: "Eva Klockar",
    runner2: "Jessica Blume",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:58:50.008Z",
    officialtime: "00:56:37",
    runnersclass: "dam",
  },
  {
    _id: 17,
    runner1: "Andreas O.H.  Granholm",
    runner2: "Sonny  Antonsson",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:44:43.885Z",
    officialtime: "00:42:31",
    runnersclass: "herr",
  },
  {
    _id: 18,
    runner1: "Maria René ",
    runner2: "Kristina  Mjörnell",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:54:16.977Z",
    officialtime: "00:52:04",
    runnersclass: "dam",
  },
  {
    _id: 19,
    runner1: "Fredrik  Asztely ",
    runner2: "Magnus Stig Rene",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:03:50.335Z",
    officialtime: "01:01:37",
    runnersclass: "herr",
  },
  {
    _id: 20,
    runner1: "MARIA Lindborg",
    runner2: "Anders Lindborg",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:58:27.570Z",
    officialtime: "00:56:14",
    runnersclass: "mixed",
  },
  {
    _id: 21,
    runner1: "Elis Malmeström",
    runner2: "Clas Malmeström",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:33.318Z",
    officialtime: "00:53:19",
    runnersclass: "herr",
  },
  {
    _id: 22,
    runner1: "Ulrika Hanse",
    runner2: "Gunilla Sondén",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:03:36.611Z",
    officialtime: "00:59:23",
    runnersclass: "dam",
  },
  {
    _id: 23,
    runner1: "Göran Karestrand",
    runner2: "Eric Hanse",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:51:47.410Z",
    officialtime: "00:47:33",
    runnersclass: "herr",
  },
  {
    _id: 224,
    runner1: "Mattias Klysing",
    runner2: "Axel Klysing",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:27:59.655Z",
    officialtime: "00:26:41",
    runnersclass: "herr",
  },
  {
    _id: 24,
    runner1: "Liselott Ehrnberg",
    runner2: "Matts Rene",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:16.138Z",
    officialtime: "00:53:02",
    runnersclass: "mixed",
  },
  {
    _id: 25,
    runner1: "Thøger Larsen",
    runner2: "Magnus Larsen",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:46:19.408Z",
    officialtime: "00:42:05",
    runnersclass: "herr",
  },
  {
    _id: 26,
    runner1: "Stina Kjellström ",
    runner2: "Malin  Karlsson ",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:23.128Z",
    officialtime: "00:53:09",
    runnersclass: "dam",
  },
  {
    _id: 27,
    runner1: "Sara Cato",
    runner2: "Elin Smärgel",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:05:49.714Z",
    officialtime: "01:01:36",
    runnersclass: "dam",
  },
  {
    _id: 28,
    runner1: "Åsa Leffler",
    runner2: "Mattias Bengtsson",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:52:25.626Z",
    officialtime: "00:48:12",
    runnersclass: "mixed",
  },
  {
    _id: 225,
    runner1: "Runa Joelsson",
    runner2: "Nils Joelsson",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:26:02.463Z",
    officialtime: "00:24:43",
    runnersclass: "mixed",
  },
  {
    _id: 226,
    runner1: "My Yousofi",
    runner2: "Arian Yousofi",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:27:32.771Z",
    officialtime: "00:26:14",
    runnersclass: "mixed",
  },
  {
    _id: 29,
    runner1: "Beb Hellekant",
    runner2: "Magnus Hellekant",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:04:13.595Z",
    officialtime: "01:00:00",
    runnersclass: "mixed",
  },
  {
    _id: 30,
    runner1: "Henrik Sjöman",
    runner2: "Frida Wiigh",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:53:36.926Z",
    officialtime: "00:49:23",
    runnersclass: "mixed",
  },
  {
    _id: 31,
    runner1: "Niclas Karlsson",
    runner2: "Andreas Johansson",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:54:04.388Z",
    officialtime: "00:47:51",
    runnersclass: "herr",
  },
  {
    _id: 32,
    runner1: "mette broegaard",
    runner2: "oscar oliva torstensson",
    race: "lång",
    startgroup: 4,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 33,
    runner1: "Susanne Düning",
    runner2: "Linda Hällström",
    race: "lång",
    startgroup: 4,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "dam",
  },
  {
    _id: 227,
    runner1: "Evelina  Granholm",
    runner2: "Johan Granholm",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:21:32.575Z",
    officialtime: "00:20:14",
    runnersclass: "mixed",
  },
  {
    _id: 34,
    runner1: "Maria Hjelte",
    runner2: "Karin Elam",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:00:42.222Z",
    officialtime: "00:54:29",
    runnersclass: "dam",
  },
  {
    _id: 35,
    runner1: "Magnus  Kämpelycke",
    runner2: "Tyler Kämpelycke",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:56:26.996Z",
    officialtime: "00:50:14",
    runnersclass: "herr",
  },
  {
    _id: 36,
    runner1: "Johanna Ström",
    runner2: "Christian  Ström",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:00:03.227Z",
    officialtime: "00:53:50",
    runnersclass: "mixed",
  },
  {
    _id: 37,
    runner1: "Nanna Bergendahl",
    runner2: "Holger Bergendahl",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:47:43.743Z",
    officialtime: "00:41:31",
    runnersclass: "mixed",
  },
  {
    _id: 38,
    runner1: "Anna Hörnqvist",
    runner2: "Ingela  Ahlstrand",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:08:32.272Z",
    officialtime: "01:02:19",
    runnersclass: "dam",
  },
  {
    _id: 39,
    runner1: "Karin Bäck",
    runner2: "John Bäck",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:51:56.321Z",
    officialtime: "00:45:43",
    runnersclass: "mixed",
  },
  {
    _id: 40,
    runner1: "Veronica Stenberg",
    runner2: "Monika Daleng",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:02:02.349Z",
    officialtime: "00:55:49",
    runnersclass: "dam",
  },
  {
    _id: 41,
    runner1: "Karin  Algell",
    runner2: "Emma Ardbo Johnard",
    race: "lång",
    startgroup: 5,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "dam",
  },
  {
    _id: 42,
    runner1: "Lena Särehag",
    runner2: "Lillemor Johansson",
    race: "lång",
    startgroup: 5,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "dam",
  },
  {
    _id: 43,
    runner1: "Emma Mäsak",
    runner2: "Marielouise Widegren",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:03:30.971Z",
    officialtime: "00:55:26",
    runnersclass: "dam",
  },
  {
    _id: 44,
    runner1: "Eric Ohlsson ",
    runner2: "Jennie  Ohlsson",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:47:33.940Z",
    officialtime: "00:39:29",
    runnersclass: "mixed",
  },
  {
    _id: 45,
    runner1: "Annie Crona Österström ",
    runner2: "Per Österström ",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:00.229Z",
    officialtime: "00:48:55",
    runnersclass: "mixed",
  },
  {
    _id: 46,
    runner1: "Michael Crona",
    runner2: "Wilhelm  Jonsson",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:45:18.636Z",
    officialtime: "00:37:14",
    runnersclass: "herr",
  },
  {
    _id: 47,
    runner1: "Kristian Heikkinen",
    runner2: "Dick Andersson",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:48:16.985Z",
    officialtime: "00:40:12",
    runnersclass: "herr",
  },
  {
    _id: 228,
    runner1: "Carolin Josefsson",
    runner2: "Alexander  Josefsson",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:25:57.026Z",
    officialtime: "00:24:38",
    runnersclass: "mixed",
  },
  {
    _id: 48,
    runner1: "Louise Erkers",
    runner2: "Oskar Lundkvist",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:03.399Z",
    officialtime: "00:48:58",
    runnersclass: "mixed",
  },
  {
    _id: 49,
    runner1: "Lisa Wallin",
    runner2: "Johanna  Skoglund ",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:56:38.189Z",
    officialtime: "00:48:33",
    runnersclass: "dam",
  },
  {
    _id: 229,
    runner1: "Ewa Bewick ",
    runner2: "Patrick  Bewick",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:35:26.018Z",
    officialtime: "00:34:07",
    runnersclass: "mixed",
  },
  {
    _id: 50,
    runner1: "Yvonne Mårtensson",
    runner2: "Elinor Olausson",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:09:56.542Z",
    officialtime: "01:01:52",
    runnersclass: "dam",
  },
  {
    _id: 230,
    runner1: "Ulysse Björk Jortay",
    runner2: "Ida Addinsall",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:26:29.299Z",
    officialtime: "00:22:58",
    runnersclass: "mixed",
  },
  {
    _id: 231,
    runner1: "Vera Addinsall",
    runner2: "Billie Björk Jortay",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:27:57.446Z",
    officialtime: "00:24:26",
    runnersclass: "mixed",
  },
  {
    _id: 51,
    runner1: "Annika Eriksson",
    runner2: "Axel Eriksson",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:52:27.753Z",
    officialtime: "00:42:11",
    runnersclass: "mixed",
  },
  {
    _id: 52,
    runner1: "Carl Johansson ",
    runner2: "Johan Hjelmaeus ",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:56:16.499Z",
    officialtime: "00:46:00",
    runnersclass: "herr",
  },
  {
    _id: 53,
    runner1: "Fabian Ojde",
    runner2: "Rikard Eriksson",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:00:08.409Z",
    officialtime: "00:49:52",
    runnersclass: "herr",
  },
  {
    _id: 54,
    runner1: "Christine  Florberger",
    runner2: "Wilhelm  Florberger",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:06:49.902Z",
    officialtime: "00:56:33",
    runnersclass: "mixed",
  },
  {
    _id: 55,
    runner1: "Filip Claes",
    runner2: "Vilmer Persson",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:35.728Z",
    officialtime: "00:47:19",
    runnersclass: "herr",
  },
  {
    _id: 56,
    runner1: "Mariella Bewick",
    runner2: "Jenny Kjellsdotter",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:25.548Z",
    officialtime: "00:47:09",
    runnersclass: "dam",
  },
  {
    _id: 232,
    runner1: "Ellen Wennebrink",
    runner2: "Sebastian Arneland",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:29:53.132Z",
    officialtime: "00:26:22",
    runnersclass: "mixed",
  },
  {
    _id: 233,
    runner1: "Elsa Ohlsson",
    runner2: "Oscar Ohlsson",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:26:43.691Z",
    officialtime: "00:23:12",
    runnersclass: "mixed",
  },
  {
    _id: 234,
    runner1: "Ludvig  Högberg ",
    runner2: "Teodor Eckerstein",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:25:28.443Z",
    officialtime: "00:21:57",
    runnersclass: "herr",
  },
  {
    _id: 57,
    runner1: "David Eriksson",
    runner2: "Daniel Sunnerek",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:47:28.965Z",
    officialtime: "00:37:12",
    runnersclass: "herr",
  },
  {
    _id: 58,
    runner1: "Jonas Hallbäck",
    runner2: "Viggo  Hallbäck",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:56:32.349Z",
    officialtime: "00:46:15",
    runnersclass: "herr",
  },
  {
    _id: 235,
    runner1: "Molly  Persson",
    runner2: "Maja Klysing",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:27:15.775Z",
    officialtime: "00:23:44",
    runnersclass: "dam",
  },
  {
    _id: 59,
    runner1: "Erik Hallgren",
    runner2: "Karl Hallgren",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:56:07.649Z",
    officialtime: "00:45:51",
    runnersclass: "herr",
  },
  {
    _id: 60,
    runner1: "Freja Freedman",
    runner2: "Viktor Wecke",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:46.046Z",
    officialtime: "00:47:29",
    runnersclass: "mixed",
  },
  {
    _id: 61,
    runner1: "Ludvig Jademyr",
    runner2: "David Sällström",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:52:14.723Z",
    officialtime: "00:39:59",
    runnersclass: "herr",
  },
  {
    _id: 62,
    runner1: "Niklas Wittström",
    runner2: "Victor Jademyr",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:47:35.614Z",
    officialtime: "00:35:20",
    runnersclass: "herr",
  },
  {
    _id: 63,
    runner1: "Johan Åhlberg",
    runner2: "Bo Johansson",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:00:01.417Z",
    officialtime: "00:47:46",
    runnersclass: "herr",
  },
  {
    _id: 236,
    runner1: "Mats Tullberg",
    runner2: "Arvid Tullberg Sundholm",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:23:45.955Z",
    officialtime: "00:20:15",
    runnersclass: "herr",
  },
  {
    _id: 64,
    runner1: "Anders Eliasson",
    runner2: "Thomas Alvling",
    race: "lång",
    startgroup: 7,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "herr",
  },
  {
    _id: 237,
    runner1: "Karin Mark",
    runner2: "Linus  Mark",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:24:53.142Z",
    officialtime: "00:21:22",
    runnersclass: "mixed",
  },
  {
    _id: 65,
    runner1: "Johan Söderlund",
    runner2: "Sebastian  Arneland ",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:53:05.632Z",
    officialtime: "00:40:50",
    runnersclass: "herr",
  },
  {
    _id: 66,
    runner1: "Nikki Schei",
    runner2: "Line Schei",
    race: "lång",
    startgroup: 7,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 67,
    runner1: "Helene Ahlberg",
    runner2: "Andreas Josefsson",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:06.539Z",
    officialtime: "00:44:51",
    runnersclass: "mixed",
  },
  {
    _id: 68,
    runner1: "Jessica Didrikson",
    runner2: "Hjalmar Didrikson",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:54:24.339Z",
    officialtime: "00:42:09",
    runnersclass: "mixed",
  },
  {
    _id: 69,
    runner1: "Jennifer Karlsson",
    runner2: "Joel Myrberg Andersson",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:52.765Z",
    officialtime: "00:45:37",
    runnersclass: "mixed",
  },
  {
    _id: 70,
    runner1: "Richard Hallgren",
    runner2: "Vanda Westman",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:59:29.921Z",
    officialtime: "00:47:14",
    runnersclass: "mixed",
  },
  {
    _id: 71,
    runner1: "Veronica Reinholdsson",
    runner2: "Noel Reinholdsson",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:58:50.269Z",
    officialtime: "00:44:15",
    runnersclass: "mixed",
  },
  {
    _id: 72,
    runner1: "Tobias Westling",
    runner2: "Anna Krona Westling",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:59:12.808Z",
    officialtime: "00:44:37",
    runnersclass: "mixed",
  },
  {
    _id: 73,
    runner1: "Emil Carlsson",
    runner2: "Anton Elfström",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:57:27.858Z",
    officialtime: "00:42:53",
    runnersclass: "herr",
  },
  {
    _id: 74,
    runner1: "Kate Swart",
    runner2: "Josh Swart",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:20:02.944Z",
    officialtime: "01:05:28",
    runnersclass: "mixed",
  },
  {
    _id: 75,
    runner1: "Andreas Larsson",
    runner2: "Martin Thurehag",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:58:22.860Z",
    officialtime: "00:43:48",
    runnersclass: "herr",
  },
  {
    _id: 76,
    runner1: "William Hägg",
    runner2: "Anton Hägg",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:49:45.210Z",
    officialtime: "00:35:10",
    runnersclass: "herr",
  },
  {
    _id: 77,
    runner1: "Elin Sjöholm ",
    runner2: "Robert  Sjöholm ",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:04:30.065Z",
    officialtime: "00:49:55",
    runnersclass: "mixed",
  },
  {
    _id: 78,
    runner1: "Catarina  Ahlberg ",
    runner2: "Camilla  Thorén ",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:18:40.325Z",
    officialtime: "01:04:05",
    runnersclass: "dam",
  },
  {
    _id: 238,
    runner1: "Erik Axelsson ",
    runner2: "Anna Axelsson ",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:29:22.547Z",
    officialtime: "00:25:51",
    runnersclass: "mixed",
  },
  {
    _id: 79,
    runner1: "Isak Axelsson ",
    runner2: "Patrik  Axelsson ",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:04:19.498Z",
    officialtime: "00:49:44",
    runnersclass: "herr",
  },
  {
    _id: 81,
    runner1: "Hanna  Ward",
    runner2: "Isabella Ward",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:29:46.601Z",
    officialtime: "01:12:23",
    runnersclass: "dam",
  },
  {
    _id: 82,
    runner1: "Ulrik Henriksson",
    runner2: "Stina-Cajsa  Berlin",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:54:54.699Z",
    officialtime: "00:37:31",
    runnersclass: "mixed",
  },
  {
    _id: 239,
    runner1: "Rikard Blom",
    runner2: "Vincent  Blom",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:31:03.638Z",
    officialtime: "00:27:32",
    runnersclass: "herr",
  },
  {
    _id: 83,
    runner1: "Karin Rhedin",
    runner2: "Elsa Blank",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:19:04.193Z",
    officialtime: "01:01:41",
    runnersclass: "dam",
  },
  {
    _id: 240,
    runner1: "Kalle Didrikson",
    runner2: "August Somp",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:25:56.763Z",
    officialtime: "00:20:09",
    runnersclass: "herr",
  },
  {
    _id: 84,
    runner1: "Frida Rhedin",
    runner2: "Ines Rhedin",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:19:01.143Z",
    officialtime: "01:01:38",
    runnersclass: "dam",
  },
  {
    _id: 241,
    runner1: "Gustav Rhedin",
    runner2: "Ville  Rhedin",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:28:55.519Z",
    officialtime: "00:23:07",
    runnersclass: "herr",
  },
  {
    _id: 85,
    runner1: "Roger Nyberg",
    runner2: "Frida Nyberg",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:10:25.102Z",
    officialtime: "00:53:02",
    runnersclass: "mixed",
  },
  {
    _id: 86,
    runner1: "Sara Olsson",
    runner2: "Magnus  Myhrborg ",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:52:47.304Z",
    officialtime: "00:35:24",
    runnersclass: "mixed",
  },
  {
    _id: 87,
    runner1: "Lisa Rendahl",
    runner2: "Malin Sundlöf",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:06:16.171Z",
    officialtime: "00:48:53",
    runnersclass: "dam",
  },
  {
    _id: 242,
    runner1: "Calle  Rendahl",
    runner2: "Malou Rendahl",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:32:21.126Z",
    officialtime: "00:26:33",
    runnersclass: "mixed",
  },
  {
    _id: 243,
    runner1: "Pelle Rendahl",
    runner2: "Moa Nylander",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:25:25.192Z",
    officialtime: "00:19:37",
    runnersclass: "mixed",
  },
  {
    _id: 244,
    runner1: "Mats Nylander",
    runner2: "Li Nylander",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:28:13.756Z",
    officialtime: "00:22:26",
    runnersclass: "mixed",
  },
  {
    _id: 88,
    runner1: "Joachim  Strömbergson ",
    runner2: "Karin Tidlund",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:23:55.888Z",
    officialtime: "01:06:32",
    runnersclass: "mixed",
  },
  {
    _id: 245,
    runner1: "Sascha Werner",
    runner2: "Alwin Norlén",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:20:03.773Z",
    officialtime: "00:14:16",
    runnersclass: "herr",
  },
  {
    _id: 89,
    runner1: "Petra Godhe",
    runner2: "Charlotta  Ling",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:11:58.652Z",
    officialtime: "00:54:35",
    runnersclass: "dam",
  },
  {
    _id: 246,
    runner1: "Ester  Ohlsson ",
    runner2: "Mats  Ohlsson ",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:29:12.467Z",
    officialtime: "00:23:24",
    runnersclass: "mixed",
  },
  {
    _id: 90,
    runner1: "Ines Rydehn",
    runner2: "Martin Rydehn",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:03:39.461Z",
    officialtime: "00:46:16",
    runnersclass: "mixed",
  },
  {
    _id: 91,
    runner1: "Rebecca Hymnelius",
    runner2: "Ken Hymnelius",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:10:46.384Z",
    officialtime: "00:51:38",
    runnersclass: "mixed",
  },
  {
    _id: 92,
    runner1: "Karin Bremer",
    runner2: "Maria  Gutke  Nigam ",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:03:31.179Z",
    officialtime: "00:44:23",
    runnersclass: "dam",
  },
  {
    _id: 93,
    runner1: "Anna Petersson",
    runner2: "Patrk Petersson",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:04:50.790Z",
    officialtime: "00:45:43",
    runnersclass: "mixed",
  },
  {
    _id: 94,
    runner1: "Pär Berg",
    runner2: "Andreas Jönsson",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:03:19.989Z",
    officialtime: "00:44:12",
    runnersclass: "herr",
  },
  {
    _id: 1,
    runner1: "Filip Axell",
    runner2: "Fredrik Stenquist",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:45:45.958Z",
    officialtime: "00:45:23",
    runnersclass: "herr",
  },
  {
    _id: 95,
    runner1: "Håkan  Skutberg",
    runner2: "Maria Skutberg ",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:09:46.815Z",
    officialtime: "00:50:39",
    runnersclass: "mixed",
  },
  {
    _id: 247,
    runner1: "Olivia  Skutberg",
    runner2: "Melvin Skutberg",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:27:46.287Z",
    officialtime: "00:21:58",
    runnersclass: "mixed",
  },
  {
    _id: 248,
    runner1: "Emil Eckerstein",
    runner2: "Samuel Eckerstein",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:27:17.276Z",
    officialtime: "00:21:29",
    runnersclass: "herr",
  },
  {
    _id: 96,
    runner1: "Viktor Nyberg",
    runner2: "Martin Svahn",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:08:29.347Z",
    officialtime: "00:49:21",
    runnersclass: "herr",
  },
  {
    _id: 97,
    runner1: "Selma Moqvist",
    runner2: "Lovisa Moqvist",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:09:08.073Z",
    officialtime: "00:50:00",
    runnersclass: "dam",
  },
  {
    _id: 98,
    runner1: "Carl-Henrik Somp",
    runner2: "Andreas Schwyzer",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:02:25.708Z",
    officialtime: "00:43:18",
    runnersclass: "herr",
  },
  {
    _id: 99,
    runner1: "Hanna Niklasson",
    runner2: "Malin Tengelin",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:07:52.043Z",
    officialtime: "00:48:44",
    runnersclass: "dam",
  },
  {
    _id: 100,
    runner1: " Charlotta  Larsjö ",
    runner2: "Tim Maalsnes ",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:13:45.532Z",
    officialtime: "00:54:38",
    runnersclass: "mixed",
  },
  {
    _id: 249,
    runner1: "Lilly Nilausen",
    runner2: "Henrik Nilausen",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:31:14.143Z",
    officialtime: "00:25:26",
    runnersclass: "mixed",
  },
  {
    _id: 101,
    runner1: "Ameli Redkvist",
    runner2: "Therese  Håkansson ",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:21:45.367Z",
    officialtime: "01:01:19",
    runnersclass: "dam",
  },
  {
    _id: 102,
    runner1: "Ekaterina Leffler",
    runner2: "Jens Hjerling Leffler",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:30:35.585Z",
    officialtime: "01:10:09",
    runnersclass: "mixed",
  },
  {
    _id: 250,
    runner1: "Niklas Carlén",
    runner2: "Ville Carlén",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:32:59.634Z",
    officialtime: "00:25:00",
    runnersclass: "herr",
  },
  {
    _id: 251,
    runner1: "Håkan Dalemo",
    runner2: "Gustav Dalemo",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:27:54.915Z",
    officialtime: "00:19:55",
    runnersclass: "herr",
  },
  {
    _id: 252,
    runner1: "Fredrik Blomberg",
    runner2: "Ted Blomberg",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:28:26.998Z",
    officialtime: "00:20:27",
    runnersclass: "herr",
  },
  {
    _id: 103,
    runner1: "Jonas Jonsson",
    runner2: "Johan Bååthe",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:07:51.621Z",
    officialtime: "00:47:25",
    runnersclass: "herr",
  },
  {
    _id: 104,
    runner1: "Carl-Johan Jonsson",
    runner2: "Alice Bååthe",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:02:04.151Z",
    officialtime: "00:41:37",
    runnersclass: "mixed",
  },
  {
    _id: 105,
    runner1: "Leo Weber Landgren",
    runner2: "Mikael Nilsson",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:19:11.779Z",
    officialtime: "00:58:45",
    runnersclass: "herr",
  },
  {
    _id: 106,
    runner1: "Caspar Andren",
    runner2: "Jakob Holmberg",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:05:05.822Z",
    officialtime: "00:44:39",
    runnersclass: "herr",
  },
  {
    _id: 253,
    runner1: "Olle Lindkvist",
    runner2: "Vida Lindkvist",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:31:58.084Z",
    officialtime: "00:23:58",
    runnersclass: "mixed",
  },
  {
    _id: 80,
    runner1: "Susanna Eckerstein",
    runner2: "Emil Eckerstein",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T11:40:03.114Z",
    officialtime: "00:39:40",
    runnersclass: "mixed",
  },
  {
    _id: 107,
    runner1: "Cecilia Michel",
    runner2: "Anger Michel",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:06:41.779Z",
    officialtime: "00:46:15",
    runnersclass: "mixed",
  },
  {
    _id: 108,
    runner1: "Emil Storm",
    runner2: "Melker Kyllerman",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:00:04.729Z",
    officialtime: "00:39:38",
    runnersclass: "herr",
  },
  {
    _id: 109,
    runner1: "Pontus Persson",
    runner2: "Matilda Persson",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2022-07-16T12:12:32.932Z",
    officialtime: "00:52:06",
    runnersclass: "mixed",
  },
];

export default runners;
