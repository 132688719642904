let runners = [
  {
    _id: 2,
    runner1: "Thøger Larsen",
    runner2: "Synne Larsen",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:44:52.832Z",
    officialtime: "00:44:30",
  },
  {
    _id: 3,
    runner1: "Patrik Lundblad",
    runner2: "Tina Lundblad",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:48:55.879Z",
    officialtime: "00:48:33",
  },
  {
    _id: 4,
    runner1: "Noel Massot",
    runner2: "Casper Mycklebust",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:39:53.671Z",
    officialtime: "00:39:31",
  },
  {
    _id: 320,
    runner1: "David Lundblad",
    runner2: "Elin Lundblad",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:18:45.630Z",
    officialtime: "00:18:30",
  },
  {
    _id: 5,
    runner1: "Oliver Heikkinen",
    runner2: "Kristian Heikkinen",
    race: "mellan",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:27:07.227Z",
    officialtime: "00:25:00",
  },
  {
    _id: 6,
    runner1: "Jonathan Redvall",
    runner2: "Theodor Redvall",
    race: "lång",
    runnersclass: "herr",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 7,
    runner1: "Olivia Krook",
    runner2: "Sofia Heymann ",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:54:20.345Z",
    officialtime: "00:53:58",
  },
  {
    _id: 8,
    runner1: "Tobbe  Wittström",
    runner2: "Eva Wittström",
    race: "lång",
    runnersclass: "mixed",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 9,
    runner1: "Lovisa Carlsson ",
    runner2: "Andreas  Gröning ",
    race: "lång",
    runnersclass: "mixed",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 10,
    runner1: "Mats Tullberg",
    runner2: "Kristina  Sundholm ",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:48:39.892Z",
    officialtime: "00:48:17",
  },
  {
    _id: 11,
    runner1: "Anders Eliasson",
    runner2: "Thomas Alvling",
    race: "lång",
    runnersclass: "herr",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 13,
    runner1: "Anton Enmark",
    runner2: "Mia Enmark ",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:54:22.155Z",
    officialtime: "00:51:27",
  },
  {
    _id: 14,
    runner1: "Malin Storm",
    runner2: "Ella Storm",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:46:28.018Z",
    officialtime: "00:43:33",
  },
  {
    _id: 15,
    runner1: "Gunnar Wide /Daniel Hofberg",
    runner2: "Fredrik  Ljungblad",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:51:36.482Z",
    officialtime: "00:48:41",
  },
  {
    _id: 16,
    runner1: "Sebastian Ljungblad",
    runner2: "Emil Storm",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:44:56.454Z",
    officialtime: "00:42:01",
  },
  {
    _id: 17,
    runner1: "Johanna  Ransved Nilsson",
    runner2: "Christian  Ström",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:45:44.869Z",
    officialtime: "00:42:49",
  },
  {
    _id: 18,
    runner1: "Moa Fredriksson",
    runner2: "Karin Bäck",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:51:40.103Z",
    officialtime: "00:48:45",
  },
  {
    _id: 19,
    runner1: "Hjalmar Didrikson",
    runner2: "Jessica Didrikson",
    race: "lång",
    runnersclass: "mixed",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 20,
    runner1: "Emil Eckerstein ",
    runner2: "Susanna Eckerstein ",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:43:22.902Z",
    officialtime: "00:40:27",
  },
  {
    _id: 21,
    runner1: "Caroline Ohlsson",
    runner2: "Mats Ohlsson",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:46:48.779Z",
    officialtime: "00:43:53",
  },
  {
    _id: 22,
    runner1: "Maria Hofberg",
    runner2: "Daniel Hofberg",
    race: "lång",
    runnersclass: "mixed",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 23,
    runner1: "Alma Hofberg",
    runner2: "Olivia Hofberg",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:06:15.952Z",
    officialtime: "01:01:12",
  },
  {
    _id: 24,
    runner1: "Maria  Gutke Nigam",
    runner2: "Karin Bremer",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:53:42.284Z",
    officialtime: "00:48:39",
  },
  {
    _id: 25,
    runner1: "Frida Dahlberg",
    runner2: "Erik Nilsson",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:51:28.291Z",
    officialtime: "00:46:25",
  },
  {
    _id: 26,
    runner1: "Ramona Byrö",
    runner2: "Anders Svensson",
    race: "lång",
    runnersclass: "mixed",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 331,
    runner1: "Emma Hemmingsson",
    runner2: "Håkan  Rahbe",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:21:23.535Z",
    officialtime: "00:21:08",
  },
  {
    _id: 27,
    runner1: "Ken Hymnelius",
    runner2: "Kristina Hymnelius",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:01:23.502Z",
    officialtime: "00:56:20",
  },
  {
    _id: 28,
    runner1: "Karin Elam",
    runner2: "Maria Hjelte",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:57:47.006Z",
    officialtime: "00:52:44",
  },
  {
    _id: 29,
    runner1: "Claes Svahn",
    runner2: "Gunnar Schymberg ",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:03:25.148Z",
    officialtime: "00:58:22",
  },
  {
    _id: 30,
    runner1: "Hanna Ward",
    runner2: "Elin Ward",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:48:39.463Z",
    officialtime: "00:43:36",
  },
  {
    _id: 32,
    runner1: "Jonas  Härdner",
    runner2: "Simon Härdner",
    race: "lång",
    runnersclass: "herr",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 33,
    runner1: "Elias Seldén",
    runner2: "Anders  Levin",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:52:26.540Z",
    officialtime: "00:45:06",
  },
  {
    _id: 35,
    runner1: "Johanna Öhrnberg",
    runner2: "Amanda Gustavsson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:57:18.946Z",
    officialtime: "00:49:58",
  },
  {
    _id: 38,
    runner1: "Susanna Eckerstein",
    runner2: "Emil Eckerstein",
    race: "lång",
    runnersclass: "mixed",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 39,
    runner1: "Hanna Uddenberg",
    runner2: "Carl Högberg ",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:21:35.142Z",
    officialtime: "01:14:15",
  },
  {
    _id: 332,
    runner1: "Magnus  Sandgren ",
    runner2: "Philip  Sandgren ",
    race: "mellan",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:19:36.681Z",
    officialtime: "00:19:21",
  },
  {
    _id: 333,
    runner1: "Catrin  Sandgren ",
    runner2: "Ida Sandgren ",
    race: "mellan",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:20:37.341Z",
    officialtime: "00:20:22",
  },
  {
    _id: 334,
    runner1: "Ingrid Kylén",
    runner2: "Ronja Kylén",
    race: "mellan",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:20:16.497Z",
    officialtime: "00:20:01",
  },
  {
    _id: 40,
    runner1: "Per Skogsberg",
    runner2: "Martin  Andersson",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:48:41.463Z",
    officialtime: "00:41:21",
  },
  {
    _id: 41,
    runner1: "Nanna Bergendahl",
    runner2: "Holger Bergendahl",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:51:07.720Z",
    officialtime: "00:43:47",
  },
  {
    _id: 42,
    runner1: "Anton Stubberud",
    runner2: "Lars Stubberud",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:58:32.103Z",
    officialtime: "00:51:12",
  },
  {
    _id: 43,
    runner1: "Ulrik Henriksson",
    runner2: "Stina-Cajsa Berlin",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:44:47.420Z",
    officialtime: "00:37:27",
  },
  {
    _id: 44,
    runner1: "Andreas Ljungqvist",
    runner2: "Jan Ljungqvist",
    race: "lång",
    runnersclass: "herr",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 45,
    runner1: "Olof Billebo",
    runner2: "Edit Eliasson",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:56:49.256Z",
    officialtime: "00:49:29",
  },
  {
    _id: 46,
    runner1: "Simon  Wallqvist",
    runner2: "Ville Johansson",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:56:21.517Z",
    officialtime: "00:46:55",
  },
  {
    _id: 47,
    runner1: "Liselott Henriksson",
    runner2: "Mia Engström",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:56:24.786Z",
    officialtime: "00:46:58",
  },
  {
    _id: 48,
    runner1: "Olof Henriksson",
    runner2: "Inger Marie Smestad",
    race: "lång",
    runnersclass: "mixed",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 49,
    runner1: "Axel Henriksson",
    runner2: "Tilda Qvist",
    race: "lång",
    runnersclass: "mixed",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 50,
    runner1: "Susanne Rodin",
    runner2: "Maria Stenman",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:53:01.863Z",
    officialtime: "00:43:35",
  },
  {
    _id: 335,
    runner1: "Love  Johnard",
    runner2: "Valdemar Boman",
    race: "mellan",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:15:46.599Z",
    officialtime: "00:15:31",
  },
  {
    _id: 51,
    runner1: "Karin  Algell",
    runner2: "Emma Ardbo Johnard",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:57:10.856Z",
    officialtime: "00:47:44",
  },
  {
    _id: 52,
    runner1: "Hannah Gregoriusson",
    runner2: "Magnus Gregoriusson",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:07:17.746Z",
    officialtime: "00:57:51",
  },
  {
    _id: 53,
    runner1: "Lisa Felixon ",
    runner2: "Frida  Rhedin ",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:57:09.275Z",
    officialtime: "00:47:43",
  },
  {
    _id: 54,
    runner1: "Christina Kämpelycke",
    runner2: "Tyler Kämpelycke",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:08:14.033Z",
    officialtime: "00:58:48",
  },
  {
    _id: 55,
    runner1: "Christian Jacobsen",
    runner2: "Louise Steengrafe",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:56:50.037Z",
    officialtime: "00:47:24",
  },
  {
    _id: 56,
    runner1: "Axel Stenmark Tullberg",
    runner2: "Isabel Harrysson Rodrigues",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:50:51.301Z",
    officialtime: "00:39:04",
  },
  {
    _id: 57,
    runner1: "Alex Krook",
    runner2: "Melker Kyllerman",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:53:07.486Z",
    officialtime: "00:41:20",
  },
  {
    _id: 58,
    runner1: "Åsa Leffler",
    runner2: "Jens Hjerling Leffler",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:04:58.627Z",
    officialtime: "00:53:11",
  },
  {
    _id: 59,
    runner1: "Mette Broegaard ",
    runner2: "Oscar Oliva Torstensson",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:05:21.837Z",
    officialtime: "00:53:34",
  },
  {
    _id: 60,
    runner1: "Fabian Ojde",
    runner2: "Rikard  Eriksson",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:08:44.393Z",
    officialtime: "00:56:57",
  },
  {
    _id: 61,
    runner1: "Jenny Jonsson",
    runner2: "Myra Neander",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:11:50.350Z",
    officialtime: "01:00:03",
  },
  {
    _id: 62,
    runner1: "Jennie Ohlsson",
    runner2: "Eric Ohlsson",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:53:32.760Z",
    officialtime: "00:41:45",
  },
  {
    _id: 63,
    runner1: "Annika Eriksson",
    runner2: "Per Eriksson",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:53:38.739Z",
    officialtime: "00:41:51",
  },
  {
    _id: 64,
    runner1: "Axel Eriksson",
    runner2: "Alessandro  Canonico",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:00:23.485Z",
    officialtime: "00:48:36",
  },
  {
    _id: 336,
    runner1: "Julia Wallen",
    runner2: "Louise  Bricknall",
    race: "mellan",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:26:41.386Z",
    officialtime: "00:26:26",
  },
  {
    _id: 65,
    runner1: "Matilda  Krüger",
    runner2: "Johan  Hederstedt",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:49:18.951Z",
    officialtime: "00:37:31",
  },
  {
    _id: 337,
    runner1: "Veronica Reinholdsson",
    runner2: "Noel Reinholdsson",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:18:42.430Z",
    officialtime: "00:18:27",
  },
  {
    _id: 66,
    runner1: "Hanna Karlsson ",
    runner2: "Maria Holmqvist Ving",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:05:15.745Z",
    officialtime: "00:51:17",
  },
  {
    _id: 67,
    runner1: "Rebecka Johannesson",
    runner2: "Tove Magnusson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:00:52.515Z",
    officialtime: "00:46:54",
  },
  {
    _id: 68,
    runner1: "William  Hägg",
    runner2: "Anton  Hägg",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:51:23.051Z",
    officialtime: "00:37:25",
  },
  {
    _id: 69,
    runner1: "Anders  Rosen",
    runner2: "Timo Minders",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:53:04.383Z",
    officialtime: "00:39:06",
  },
  {
    _id: 70,
    runner1: "Emma Söderlind",
    runner2: "Veronika Östedt",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:03:57.741Z",
    officialtime: "00:49:59",
  },
  {
    _id: 338,
    runner1: "Daniel Olausson",
    runner2: "Viktor  Olsson ",
    race: "mellan",
    runnersclass: "herr",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 71,
    runner1: "Evelina Granholm",
    runner2: "Jenny Olsson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:06:15.352Z",
    officialtime: "00:52:17",
  },
  {
    _id: 72,
    runner1: "Christina Fredriksson ",
    runner2: "Karin Eriksson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:11:07.821Z",
    officialtime: "00:57:09",
  },
  {
    _id: 73,
    runner1: "Annsofi Öhman",
    runner2: "Hilda Axelsson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:05:52.973Z",
    officialtime: "00:51:54",
  },
  {
    _id: 339,
    runner1: "Teodor Eckerstein",
    runner2: "Ludvig Uddenberg",
    race: "mellan",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:24:22.597Z",
    officialtime: "00:24:07",
  },
  {
    _id: 74,
    runner1: "Elsa Stenmark Tullberg",
    runner2: "Erik Stenmark Tullberg",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:02:39.441Z",
    officialtime: "00:48:41",
  },
  {
    _id: 75,
    runner1: "Camilla Forsström",
    runner2: "Annica Harrysson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:57:28.765Z",
    officialtime: "00:43:30",
  },
  {
    _id: 76,
    runner1: "Lisa  Wessling ",
    runner2: "Kristina Elfving",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:17:34.257Z",
    officialtime: "01:01:09",
  },
  {
    _id: 77,
    runner1: "Emelie Danielsson ",
    runner2: "Anna  Schlosser",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:54:01.842Z",
    officialtime: "00:37:36",
  },
  {
    _id: 78,
    runner1: "Jakob Holmberg",
    runner2: "Jesper Hassel",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:49:50.843Z",
    officialtime: "00:33:25",
  },
  {
    _id: 79,
    runner1: "Ulrika Bergbrant",
    runner2: "Johan  Johansson ",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:53:29.492Z",
    officialtime: "00:37:04",
  },
  {
    _id: 80,
    runner1: "Anders Carlbark",
    runner2: "Johan Bergqvist",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:59:46.389Z",
    officialtime: "00:43:21",
  },
  {
    _id: 81,
    runner1: "Ulf Stenberg ",
    runner2: "Jerker  Persson ",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:59:51.952Z",
    officialtime: "00:43:26",
  },
  {
    _id: 340,
    runner1: "Jessica Törnqvist",
    runner2: "Emrik  Törnqvist ",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:31:55.275Z",
    officialtime: "00:31:40",
  },
  {
    _id: 82,
    runner1: "Fredrik Lindblom",
    runner2: "Markus Björkman",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:07:02.874Z",
    officialtime: "00:50:37",
  },
  {
    _id: 83,
    runner1: "Michael Crona",
    runner2: "Nils Österström",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:57:02.625Z",
    officialtime: "00:40:37",
  },
  {
    _id: 84,
    runner1: "Per Österström",
    runner2: "Annie  Österström",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:01:35.682Z",
    officialtime: "00:45:10",
  },
  {
    _id: 85,
    runner1: "Andreas Larsson",
    runner2: "Martin Thurehag",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:00:33.526Z",
    officialtime: "00:44:08",
  },
  {
    _id: 86,
    runner1: "Cecilia Jondring Sarv",
    runner2: "Teresia Gerdén",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:14:33.470Z",
    officialtime: "00:56:06",
  },
  {
    _id: 341,
    runner1: "Isak Axelsson",
    runner2: "Patrik Axelsson",
    race: "mellan",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:21:49.281Z",
    officialtime: "00:21:34",
  },
  {
    _id: 342,
    runner1: "Erik  Axelsson",
    runner2: "Anna Axelsson",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:24:25.698Z",
    officialtime: "00:24:10",
  },
  {
    _id: 343,
    runner1: "Mattias Klysing",
    runner2: "Axel Klysing",
    race: "mellan",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:33:15.087Z",
    officialtime: "00:31:08",
  },
  {
    _id: 344,
    runner1: "Maja Klysing",
    runner2: "Molly Persson",
    race: "mellan",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:28:06.702Z",
    officialtime: "00:25:59",
  },
  {
    _id: 87,
    runner1: "Matilda Persson",
    runner2: "Emma Claes",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:08:52.552Z",
    officialtime: "00:50:25",
  },
  {
    _id: 88,
    runner1: "Filip Claes",
    runner2: "Vilmer Persson",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:10:39.427Z",
    officialtime: "00:52:12",
  },
  {
    _id: 89,
    runner1: "Malva  Arvidsson",
    runner2: "Tilia Olsson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:56:43.896Z",
    officialtime: "00:38:16",
  },
  {
    _id: 345,
    runner1: "Ebba Larsson",
    runner2: "Anette Larsson",
    race: "mellan",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:25:40.638Z",
    officialtime: "00:23:33",
  },
  {
    _id: 90,
    runner1: "Andreas Granholm",
    runner2: "Sonny  Antonsson",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:03:57.962Z",
    officialtime: "00:45:30",
  },
  {
    _id: 91,
    runner1: "Johan  Söderlund ",
    runner2: "Sebastian  Arneland ",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:01:48.086Z",
    officialtime: "00:43:21",
  },
  {
    _id: 92,
    runner1: "Staffan Nilson",
    runner2: "Alvar  Wedin Nilson",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:05:20.563Z",
    officialtime: "00:46:53",
  },
  {
    _id: 93,
    runner1: "Tova Geremyr ",
    runner2: "Filip  Holmqvist ",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:08:50.513Z",
    officialtime: "00:50:23",
  },
  {
    _id: 94,
    runner1: "Viktor Thendahl",
    runner2: "Emma Mäsak",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:06:01.427Z",
    officialtime: "00:47:34",
  },
  {
    _id: 95,
    runner1: "Hampus Krook",
    runner2: "Erik Holmstedt",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:33:12.684Z",
    officialtime: "01:14:45",
  },
  {
    _id: 96,
    runner1: "Lisa Ström",
    runner2: "Charlotte  Wihman ",
    race: "lång",
    runnersclass: "dam",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
  {
    _id: 97,
    runner1: "Malin Claes",
    runner2: "José  Madueño ",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:22:39.343Z",
    officialtime: "01:01:43",
  },
  {
    _id: 98,
    runner1: "Kristin Abrahamsson",
    runner2: "Lisbeth Henriksson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:28:19.616Z",
    officialtime: "01:07:23",
  },
  {
    _id: 99,
    runner1: "Åsa Palm",
    runner2: "Henrik Antonsson",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:03:52.677Z",
    officialtime: "00:42:56",
  },
  {
    _id: 100,
    runner1: "Theo Rendahl",
    runner2: "Hampus Andersson",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:07:10.494Z",
    officialtime: "00:46:14",
  },
  {
    _id: 101,
    runner1: "Monika Daleng",
    runner2: "Veronica Stenberg",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:20:10.368Z",
    officialtime: "00:59:14",
  },
  {
    _id: 346,
    runner1: "Martin Rydehn",
    runner2: "Ines Rydehn ",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:21:20.618Z",
    officialtime: "00:19:13",
  },
  {
    _id: 102,
    runner1: "Johanna  Carlsson ",
    runner2: "Linnea Carlsson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:15:51.529Z",
    officialtime: "00:54:55",
  },
  {
    _id: 103,
    runner1: "Erik Hallgren",
    runner2: "Fredrik  Olsson ",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:58:51.623Z",
    officialtime: "00:37:55",
  },
  {
    _id: 104,
    runner1: "Runa Joelsson",
    runner2: "Sebastian  Persson ",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:11:46.126Z",
    officialtime: "00:50:50",
  },
  {
    _id: 105,
    runner1: "Mia Sandblom",
    runner2: "Morgan Sandblom",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:14:27.313Z",
    officialtime: "00:53:31",
  },
  {
    _id: 106,
    runner1: "Ludvig Arwidsson",
    runner2: "Hannes Lindvall",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:08:42.838Z",
    officialtime: "00:46:15",
  },
  {
    _id: 107,
    runner1: "Karin Elgh",
    runner2: "Björn Elgh",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:11:14.175Z",
    officialtime: "00:48:47",
  },
  {
    _id: 108,
    runner1: "Olof Bewick",
    runner2: "Mikael Viktorsson",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:58:25.363Z",
    officialtime: "00:35:58",
  },
  {
    _id: 109,
    runner1: "Mariella Bewick",
    runner2: "Jenny  Kjellsdotter",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:16:20.473Z",
    officialtime: "00:53:53",
  },
  {
    _id: 110,
    runner1: "Daniel Taube",
    runner2: "Kenny Brovall",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:56:58.605Z",
    officialtime: "00:34:31",
  },
  {
    _id: 347,
    runner1: "Peter Haknert",
    runner2: "Alma Haknert ",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:22:39.967Z",
    officialtime: "00:20:32",
  },
  {
    _id: 111,
    runner1: "Marie  Stark",
    runner2: "Fredrik Stark",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:09:29.607Z",
    officialtime: "00:47:02",
  },
  {
    _id: 348,
    runner1: "Carolin Josefsson",
    runner2: "Alexander  Josefsson",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:32:06.635Z",
    officialtime: "00:29:59",
  },
  {
    _id: 113,
    runner1: "Andreas Kronberg",
    runner2: "Anton Bengtsson",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: '"2021-07-17T11:52:22.155Z"',
    officialtime: "00:29:55",
  },
  {
    _id: 349,
    runner1: "Louise Blom",
    runner2: "Vincent  Blom",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:26:52.546Z",
    officialtime: "00:24:45",
  },
  {
    _id: 114,
    runner1: "Fritiof Lembke",
    runner2: "Rebecca Persson",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:16:27.014Z",
    officialtime: "00:54:00",
  },
  {
    _id: 115,
    runner1: "Carl Ternstedt",
    runner2: "Joel Leonard Kraapa",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:01:51.626Z",
    officialtime: "00:39:24",
  },
  {
    _id: 116,
    runner1: "Roger Nyberg",
    runner2: "Frida  Nyberg",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:13:59.295Z",
    officialtime: "00:51:32",
  },
  {
    _id: 117,
    runner1: "Regina rosander",
    runner2: "Hans Rosander",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:12:08.218Z",
    officialtime: "00:47:59",
  },
  {
    _id: 118,
    runner1: "Astrid  Parinder",
    runner2: "Michael Westerlund",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:10:11.942Z",
    officialtime: "00:46:02",
  },
  {
    _id: 350,
    runner1: "Ida Addinsall",
    runner2: "Ulysse  Björk Jortay",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:25:42.385Z",
    officialtime: "00:23:35",
  },
  {
    _id: 351,
    runner1: "Vera Addinsall ",
    runner2: "Billie Björk Jortay",
    race: "mellan",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:28:45.814Z",
    officialtime: "00:26:38",
  },
  {
    _id: 352,
    runner1: "Jennie Pernheden",
    runner2: "Karl-Evert  Pernheden",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:27:16.487Z",
    officialtime: "00:25:09",
  },
  {
    _id: 353,
    runner1: "Ville  Abrahamsson ",
    runner2: "Hanna  Abrahamsson ",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:29:32.005Z",
    officialtime: "00:27:25",
  },
  {
    _id: 354,
    runner1: "Karin  Mark",
    runner2: "Linus  Mark",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:23:31.981Z",
    officialtime: "00:21:24",
  },
  {
    _id: 119,
    runner1: "Martin  Heymann",
    runner2: "Gustav  Holst",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:08:30.357Z",
    officialtime: "00:44:21",
  },
  {
    _id: 120,
    runner1: "Morgan Staaf",
    runner2: "Jenny  Staaf",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:19:40.004Z",
    officialtime: "00:55:31",
  },
  {
    _id: 121,
    runner1: "Albin Tenstam",
    runner2: "Vilmer Tenstam",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:09:25.455Z",
    officialtime: "00:45:16",
  },
  {
    _id: 122,
    runner1: "Olle Lindkvist",
    runner2: "Lina Lindkvist",
    race: "lång",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:10:20.290Z",
    officialtime: "00:46:11",
  },
  {
    _id: 123,
    runner1: "Markus Ransved Nilsson",
    runner2: "Gustav Spiik",
    race: "lång",
    runnersclass: "herr",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:18:54.825Z",
    officialtime: "00:54:45",
  },
  {
    _id: 1,
    runner1: "Camilla Davidsson",
    runner2: "Jessica Johansson",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T11:52:06.480Z",
    officialtime: "00:51:44",
  },
  {
    _id: 355,
    runner1: "Malou Rendahl",
    runner2: "Calle Rendahl",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:30:32.694Z",
    officialtime: "00:28:25",
  },
  {
    _id: 356,
    runner1: "Pelle Rendahl",
    runner2: "Moa Nylander",
    race: "mellan",
    runnersclass: "mixed",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:22:05.530Z",
    officialtime: "00:19:58",
  },
  {
    _id: 124,
    runner1: "Lisa Rendahl",
    runner2: "Malin Sundlöf",
    race: "lång",
    runnersclass: "dam",
    checkin: true,
    finished: true,
    finishtime: "2021-07-17T12:10:53.373Z",
    officialtime: "00:46:44",
  },
  {
    _id: 357,
    runner1: "Liam Mattson Lindeström",
    runner2: "Elton Brovall",
    race: "mellan",
    runnersclass: "herr",
    checkin: true,
    finished: false,
    finishtime: null,
    officialtime: null,
  },
];

export default runners;
