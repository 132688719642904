import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import styles from "./Header.module.css";

function Header(props) {
  const [allowReg, setAllowReg] = useState(true);

  useEffect(() => {
    let today = new Date();
    let regFinished = new Date("Jul 17 2025 23:59:59 GMT+0200");
    if (today > regFinished) {
      setAllowReg(false);
    }
  }, []);
  console.log(process.env.REACT_APP_ENV);
  return (
    <div className={`${styles.Header} ${styles.shadow}`}>
      <div className={`${styles.HeaderBox}`}>
        <h1 className={`${styles.H1}`}>Stocken SOS 2025</h1>
        
        <div className={styles.anmalanDiv}>
          {allowReg ? (
            <Link to="/registration" className={styles.AnmalanButton}>
              Anmälan 2025
            </Link>
          ) : (
            <Link className={styles.AnmalanButtonClosed}>Anmälan 2025</Link>
          )}

          <h4 className={styles.H4}>19 Juli 13:00</h4>
        </div>
      </div>
    </div>
  );
}

export default Header;
