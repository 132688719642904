let runners = [
  {
    _id: 1,
    runner1: "Samir Cherif",
    runner2: "Thomas Axell",
    race: "lång",
    runnersclass: "herr",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:58:47.453Z",
    officialtime: "00:56:40"
  },
  {
    _id: 2,
    runner1: "Henrik Geremyr",
    runner2: "Alfons Geremyr",
    race: "lång",
    runnersclass: "herr",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:51:40.132Z",
    officialtime: "00:49:32"
  },
  {
    _id: 3,
    runner1: "Tova Geremyr",
    runner2: "Ingela Andersson",
    race: "lång",
    runnersclass: "dam",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:56:58.510Z",
    officialtime: "00:54:51"
  },
  {
    _id: 4,
    runner1: "Tobbe Wittstrom",
    runner2: "Eva Wittstrom",
    race: "lång",
    runnersclass: "mixed",
    startgroup: "1",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:48:54.948Z",
    officialtime: "00:46:47"
  },
  {
    _id: 5,
    runner1: "Theodor Redvall",
    runner2: "Jonathan Redvall",
    race: "lång",
    runnersclass: "herr",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:42:59.834Z",
    officialtime: "00:40:52"
  },
  {
    _id: 6,
    runner1: "Johanna ohrnberg",
    runner2: "Johan Persson",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 1,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 7,
    runner1: "Annika Nyqvist",
    runner2: "Per Nyqvist",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:02:41.071Z",
    officialtime: "01:00:33"
  },
  {
    _id: 8,
    runner1: "Liza  Grell",
    runner2: "Frida  Axell",
    race: "lång",
    runnersclass: "dam",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:52:38.218Z",
    officialtime: "00:50:30"
  },
  {
    _id: 9,
    runner1: "Viktor Grell",
    runner2: "Hampus  Krook",
    race: "lång",
    runnersclass: "herr",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:36:28.122Z",
    officialtime: "00:34:20"
  },
  {
    _id: 10,
    runner1: "Hjalmar Didrikson",
    runner2: "Jessica Didrikson",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:42:59.914Z",
    officialtime: "00:40:52"
  },
  {
    _id: 11,
    runner1: "Mia Enmark",
    runner2: "Anton Enmark",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:54:55.196Z",
    officialtime: "00:52:47"
  },
  {
    _id: 12,
    runner1: "Patrik Lundblad",
    runner2: "Anton Lundblad",
    race: "lång",
    runnersclass: "herr",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:45:49.754Z",
    officialtime: "00:43:42"
  },
  {
    _id: 13,
    runner1: "Tina Lundblad",
    runner2: "Sanna Lundblad",
    race: "lång",
    runnersclass: "dam",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:50:21.786Z",
    officialtime: "00:48:14"
  },
  {
    _id: 14,
    runner1: "Thøger Larsen",
    runner2: "Synne Larsen",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:47:25.549Z",
    officialtime: "00:42:15"
  },
  {
    _id: 15,
    runner1: "Mats Tullberg",
    runner2: "Kristina Sundholm ",
    race: "lång",
    runnersclass: "herr",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:53:48.334Z",
    officialtime: "00:48:38"
  },
  {
    _id: 16,
    runner1: "mette broegaard",
    runner2: "oscar oliva torstensson",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:55:47.154Z",
    officialtime: "00:50:37"
  },
  {
    _id: 17,
    runner1: "Emil Eckerstein",
    runner2: "Susanna Eckerstein",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:43:19.835Z",
    officialtime: "00:38:10"
  },
  {
    _id: 18,
    runner1: "Hanna Uddenberg",
    runner2: "Calle Hogberg ",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:15:30.588Z",
    officialtime: "01:10:20"
  },
  {
    _id: 19,
    runner1: "Emil Storm",
    runner2: "Gunnar Wide",
    race: "lång",
    runnersclass: "herr",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:47:44.308Z",
    officialtime: "00:42:34"
  },
  {
    _id: 20,
    runner1: "Malin Storm",
    runner2: "Ella Storm",
    race: "lång",
    runnersclass: "dam",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:46:56.564Z",
    officialtime: "00:41:46"
  },
  {
    _id: 21,
    runner1: "Johan Soderlund ",
    runner2: "Sebastian  Arneland",
    race: "lång",
    runnersclass: "herr",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:46:45.479Z",
    officialtime: "00:41:35"
  },
  {
    _id: 22,
    runner1: "Dick Andersson",
    runner2: "Kristian Heikkinen",
    race: "lång",
    runnersclass: "herr",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:42:13.904Z",
    officialtime: "00:37:04"
  },
  {
    _id: 23,
    runner1: "Helen Knutsson",
    runner2: "Hans Rasmussen",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:51.562Z",
    officialtime: "01:00:41"
  },
  {
    _id: 24,
    runner1: "Christina Kämpelycke",
    runner2: "Magnus Kämpelycke",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:58:11.070Z",
    officialtime: "00:53:01"
  },
  {
    _id: 25,
    runner1: "Maud Häggblad",
    runner2: "Cecilia Taube",
    race: "lång",
    runnersclass: "dam",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:01:01.382Z",
    officialtime: "00:55:51"
  },
  {
    _id: 26,
    runner1: "Claes Svahn",
    runner2: "Gunnar Schymberg",
    race: "lång",
    runnersclass: "herr",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:54:45.904Z",
    officialtime: "00:49:36"
  },
  {
    _id: 27,
    runner1: "Catarina Ahlberg ",
    runner2: "Jonas  Ahlberg ",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:03:37.187Z",
    officialtime: "00:55:11"
  },
  {
    _id: 28,
    runner1: "Emma Mäsak",
    runner2: "Frida Wiigh",
    race: "lång",
    runnersclass: "dam",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:58:25.325Z",
    officialtime: "00:49:59"
  },
  {
    _id: 29,
    runner1: "Karin Elam",
    runner2: "Maria Hjelte",
    race: "lång",
    runnersclass: "dam",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:57:06.333Z",
    officialtime: "00:48:40"
  },
  {
    _id: 30,
    runner1: "Marcus Brodin",
    runner2: "Åsa Palmaeus Edlund",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 3,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 31,
    runner1: "Johan Hjelmaeus ",
    runner2: "Carl Johansson",
    race: "lång",
    runnersclass: "herr",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:54:08.961Z",
    officialtime: "00:45:43"
  },
  {
    _id: 32,
    runner1: "John Lärke",
    runner2: "Robin Lärke",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 3,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 33,
    runner1: "Pernilla Hellestam",
    runner2: "Calle Hellestam",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 3,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 34,
    runner1: "Magnus Hellekant",
    runner2: "Henrik Hellekant",
    race: "lång",
    runnersclass: "herr",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:52:35.970Z",
    officialtime: "00:44:10"
  },
  {
    _id: 35,
    runner1: "Mats  Ohlsson",
    runner2: "Caroline Ohlsson",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:56:51.470Z",
    officialtime: "00:48:26"
  },
  {
    _id: 36,
    runner1: "Elina Ruus",
    runner2: "Daniel Ruus",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:52:44.948Z",
    officialtime: "00:44:19"
  },
  {
    _id: 37,
    runner1: "Johan Forslund",
    runner2: "Sebastian  Salman ",
    race: "lång",
    runnersclass: "herr",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:56:43.221Z",
    officialtime: "00:48:17"
  },
  {
    _id: 38,
    runner1: "Fredrik Asztely",
    runner2: "Magnus René",
    race: "lång",
    runnersclass: "herr",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:03:42.917Z",
    officialtime: "00:55:17"
  },
  {
    _id: 39,
    runner1: "Max Svensson",
    runner2: "Jakob René",
    race: "lång",
    runnersclass: "herr",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:54:18.450Z",
    officialtime: "00:45:53"
  },
  {
    _id: 40,
    runner1: "Pelle Pedersen",
    runner2: "Karin  Dahl Pedersen",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:00:39.748Z",
    officialtime: "00:49:06"
  },
  {
    _id: 41,
    runner1: "Ulrika Hanse",
    runner2: "Clas  Malmestrom",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:10:05.956Z",
    officialtime: "00:58:32"
  },
  {
    _id: 42,
    runner1: "Goran  Karestrand",
    runner2: "Eric Hanse",
    race: "lång",
    runnersclass: "herr",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:55:02.144Z",
    officialtime: "00:43:29"
  },
  {
    _id: 43,
    runner1: "johan granlund",
    runner2: "karin kyllerman",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:57:22.482Z",
    officialtime: "00:45:49"
  },
  {
    _id: 44,
    runner1: "Vilma  Ljungqvist",
    runner2: "Andreas Ljungqvist",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:07:27.559Z",
    officialtime: "00:55:54"
  },
  {
    _id: 45,
    runner1: "Carl Johan Ardbo",
    runner2: "Karin Ardbo",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:53:27.069Z",
    officialtime: "00:41:54"
  },
  {
    _id: 46,
    runner1: "Staffan Hedstrom",
    runner2: "Annika Hedstrom",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:59:05.451Z",
    officialtime: "00:47:32"
  },
  {
    _id: 47,
    runner1: "Matts Rene",
    runner2: "Liselott Ehrnberg",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:42.680Z",
    officialtime: "00:54:09"
  },
  {
    _id: 48,
    runner1: "Ken Hymnelius",
    runner2: "Rebecca Hymnelius",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 49,
    runner1: "Elisabet Dahllof",
    runner2: "Ulrika Petersson Nordberg",
    race: "lång",
    runnersclass: "dam",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:13.380Z",
    officialtime: "00:53:40"
  },
  {
    _id: 50,
    runner1: "Sandra Segerlind Olsson",
    runner2: "Andrea Segerlind",
    race: "lång",
    runnersclass: "dam",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:44.217Z",
    officialtime: "00:54:11"
  },
  {
    _id: 51,
    runner1: "Anna Lindstrand",
    runner2: "Hans Ribberstrom",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:11:39.137Z",
    officialtime: "01:00:06"
  },
  {
    _id: 52,
    runner1: "Kristina Holmdahl",
    runner2: "Christian White",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:12.136Z",
    officialtime: "00:53:39"
  },
  {
    _id: 53,
    runner1: "Linus Holmdahl",
    runner2: "Anna Holmdahl White",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:59:39.085Z",
    officialtime: "00:44:56"
  },
  {
    _id: 54,
    runner1: "Andreas Schondell",
    runner2: "Rickard Schondell",
    race: "lång",
    runnersclass: "herr",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:51:28.406Z",
    officialtime: "00:36:46"
  },
  {
    _id: 55,
    runner1: "Anna Bjork",
    runner2: "Ebba  Birging",
    race: "lång",
    runnersclass: "dam",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:04:05.668Z",
    officialtime: "00:49:23"
  },
  {
    _id: 56,
    runner1: "Anne  Tegner",
    runner2: "Olivia  Tegner",
    race: "lång",
    runnersclass: "dam",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:10:55.414Z",
    officialtime: "00:56:13"
  },
  {
    _id: 57,
    runner1: "Erik von der Lancken",
    runner2: "Adrian Forsen",
    race: "lång",
    runnersclass: "herr",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:58:31.824Z",
    officialtime: "00:43:49"
  },
  {
    _id: 58,
    runner1: "Joel Andersson",
    runner2: "Karl Andersson",
    race: "lång",
    runnersclass: "herr",
    startgroup: 5,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 59,
    runner1: "Leif Nilsson",
    runner2: "Henry Hägg",
    race: "lång",
    runnersclass: "herr",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:03:22.888Z",
    officialtime: "00:48:40"
  },
  {
    _id: 60,
    runner1: "Christine Varén",
    runner2: "Mattias Varén",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:03:22.456Z",
    officialtime: "00:48:40"
  },
  {
    _id: 61,
    runner1: "Jacco  Herweijer",
    runner2: "Tintin Herweijer",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:02:21.508Z",
    officialtime: "00:47:39"
  },
  {
    _id: 62,
    runner1: "Linn Elisabeth Hoel",
    runner2: "Ludvig Arwidsson",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:04:39.387Z",
    officialtime: "00:49:57"
  },
  {
    _id: 63,
    runner1: "Trond Heitmann",
    runner2: "Liselott Jademyr",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:54:31.273Z",
    officialtime: "00:39:49"
  },
  {
    _id: 64,
    runner1: "Iris Eriksen",
    runner2: "Dag Håkon Eriksen",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:00:57.822Z",
    officialtime: "00:46:15"
  },
  {
    _id: 65,
    runner1: "Sondre Heitmann",
    runner2: "Ludvig Jademyr",
    race: "lång",
    runnersclass: "herr",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:55:57.553Z",
    officialtime: "00:41:15"
  },
  {
    _id: 66,
    runner1: "Sven-Ove Johansson",
    runner2: "Linda Widmark",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:16:10.937Z",
    officialtime: "00:58:17"
  },
  {
    _id: 67,
    runner1: "Christian Jacobsen",
    runner2: "Louise Steengrafe",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:04:36.815Z",
    officialtime: "00:46:43"
  },
  {
    _id: 68,
    runner1: "Tove Magnusson",
    runner2: "Rebecka Johannesson",
    race: "lång",
    runnersclass: "dam",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:02:52.067Z",
    officialtime: "00:44:59"
  },
  {
    _id: 69,
    runner1: "Lisa Wilborg",
    runner2: "Camilo Lattof ",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:56:04.416Z",
    officialtime: "00:38:11"
  },
  {
    _id: 70,
    runner1: "Isak Widmark",
    runner2: "Anton Engblom",
    race: "lång",
    runnersclass: "herr",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:06:11.938Z",
    officialtime: "00:48:18"
  },
  {
    _id: 71,
    runner1: "Emma Ardbo Johnard",
    runner2: "Karin Algell",
    race: "lång",
    runnersclass: "dam",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:04:29.250Z",
    officialtime: "00:46:36"
  },
  {
    _id: 72,
    runner1: "Susanna Lisell Hallgren ",
    runner2: "Rikard Hallgren",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:59:25.709Z",
    officialtime: "00:41:32"
  },
  {
    _id: 73,
    runner1: "Lisa Rendahl",
    runner2: "Marie Stark",
    race: "lång",
    runnersclass: "dam",
    startgroup: 6,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 74,
    runner1: "Niklas Wittstrom",
    runner2: "Jakob Palmqvist",
    race: "lång",
    runnersclass: "herr",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:57:23.646Z",
    officialtime: "00:39:30"
  },
  {
    _id: 75,
    runner1: "Anders  Rosen",
    runner2: "Kenneth Edvardsson ",
    race: "lång",
    runnersclass: "herr",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T11:55:54.022Z",
    officialtime: "00:38:01"
  },
  {
    _id: 76,
    runner1: "Ted Thorner",
    runner2: "Caroline Gärdfors",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:13:49.990Z",
    officialtime: "00:55:57"
  },
  {
    _id: 77,
    runner1: "Marie Jarefjäll",
    runner2: "Karin Bäck",
    race: "lång",
    runnersclass: "dam",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:10:41.982Z",
    officialtime: "00:52:49"
  },
  {
    _id: 78,
    runner1: "Annsofi ohman",
    runner2: "Hilda Axelsson",
    race: "lång",
    runnersclass: "dam",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:14:00.791Z",
    officialtime: "00:56:07"
  },
  {
    _id: 79,
    runner1: "Anders Eliasson",
    runner2: "Thomas Alvling",
    race: "lång",
    runnersclass: "herr",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:55.256Z",
    officialtime: "00:44:55"
  },
  {
    _id: 80,
    runner1: "Ekaterina Leffler",
    runner2: "Jens Hjerling Leffler",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:24:34.474Z",
    officialtime: "01:03:35"
  },
  {
    _id: 81,
    runner1: "Hanna  Ward",
    runner2: "Helen Lundahl",
    race: "lång",
    runnersclass: "dam",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:11:09.531Z",
    officialtime: "00:50:10"
  },
  {
    _id: 82,
    runner1: "Daniel Hofberg ",
    runner2: "Alma Hofberg",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:18:23.702Z",
    officialtime: "00:57:24"
  },
  {
    _id: 83,
    runner1: "Maria Hofberh",
    runner2: "Olivia Hofberc",
    race: "lång",
    runnersclass: "dam",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:18:26.584Z",
    officialtime: "00:57:27"
  },
  {
    _id: 84,
    runner1: "Johanna Nilsson",
    runner2: "Christian  Strom",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:56.867Z",
    officialtime: "00:44:57"
  },
  {
    _id: 85,
    runner1: "Lovisa Carlsson",
    runner2: "Andreas  Groning",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:01:34.534Z",
    officialtime: "00:40:35"
  },
  {
    _id: 86,
    runner1: "Theo Rendahl",
    runner2: "Hampus Andersson",
    race: "lång",
    runnersclass: "herr",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:11:49.902Z",
    officialtime: "00:50:50"
  },
  {
    _id: 87,
    runner1: "Mikael  Steen",
    runner2: "Barbro Johansson Steen",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:18:08.903Z",
    officialtime: "00:57:09"
  },
  {
    _id: 88,
    runner1: "Fredrik Bergstrand",
    runner2: "Aleksander Brekke",
    race: "lång",
    runnersclass: "herr",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:01:13.971Z",
    officialtime: "00:40:14"
  },
  {
    _id: 89,
    runner1: "Karl Hallgren",
    runner2: "Erik Hallgren",
    race: "lång",
    runnersclass: "herr",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:15.954Z",
    officialtime: "00:44:16"
  },
  {
    _id: 90,
    runner1: "Kristoffer Hagstedt",
    runner2: "Ville  Johansson",
    race: "lång",
    runnersclass: "herr",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:49.264Z",
    officialtime: "00:44:49"
  },
  {
    _id: 91,
    runner1: "Sara Willforss",
    runner2: "Marja Hammarstrand",
    race: "lång",
    runnersclass: "dam",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:13:11.827Z",
    officialtime: "00:52:12"
  },
  {
    _id: 92,
    runner1: "Christine Florberger",
    runner2: "Viktor Sjostrand",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:15:51.765Z",
    officialtime: "00:51:47"
  },
  {
    _id: 93,
    runner1: "Yvonne Mårtensson",
    runner2: "Elinor Olausson",
    race: "lång",
    runnersclass: "dam",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:19:21.584Z",
    officialtime: "00:55:17"
  },
  {
    _id: 94,
    runner1: "Michael Crona",
    runner2: "Per Österström",
    race: "lång",
    runnersclass: "herr",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:03:32.487Z",
    officialtime: "00:39:28"
  },
  {
    _id: 95,
    runner1: "Linnea Hernevik",
    runner2: "Olivia Moreau",
    race: "lång",
    runnersclass: "dam",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:19:00.094Z",
    officialtime: "00:54:55"
  },
  {
    _id: 96,
    runner1: "Olof Billebo",
    runner2: "Niklas Holmberg",
    race: "lång",
    runnersclass: "herr",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:08:36.383Z",
    officialtime: "00:44:32"
  },
  {
    _id: 97,
    runner1: "Thomas Hammarklint",
    runner2: "Erik Andersson",
    race: "lång",
    runnersclass: "herr",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:04:35.145Z",
    officialtime: "00:40:30"
  },
  {
    _id: 98,
    runner1: "Anna Wirsin ",
    runner2: "Alexandra Kronberg",
    race: "lång",
    runnersclass: "dam",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:16:04.666Z",
    officialtime: "00:52:00"
  },
  {
    _id: 99,
    runner1: "Jessica Tornqvist",
    runner2: "Markus Bengtsson",
    race: "lång",
    runnersclass: "dam",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:10:18.548Z",
    officialtime: "00:46:14"
  },
  {
    _id: 100,
    runner1: "Isabelle  Westlund ",
    runner2: "Ida Weiner",
    race: "lång",
    runnersclass: "dam",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:13:01.443Z",
    officialtime: "00:48:57"
  },
  {
    _id: 101,
    runner1: "Styrbjorn  Johansson",
    runner2: "Simon Wallqvist",
    race: "lång",
    runnersclass: "herr",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:05:01.633Z",
    officialtime: "00:40:57"
  },
  {
    _id: 102,
    runner1: "Jerker  Persson",
    runner2: "Ulf Stenberg",
    race: "lång",
    runnersclass: "herr",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:04:53.217Z",
    officialtime: "00:40:48"
  },
  {
    _id: 103,
    runner1: "Matilda Krüger ",
    runner2: "Johan Hederstedt",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:02:01.585Z",
    officialtime: "00:37:57"
  },
  {
    _id: 104,
    runner1: "Ivar Henriksson",
    runner2: "Viktor  Järnekull",
    race: "lång",
    runnersclass: "herr",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:06:51.191Z",
    officialtime: "00:42:46"
  },
  {
    _id: 105,
    runner1: "Kristoffer Suner",
    runner2: "Molly Suner",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:21:32.977Z",
    officialtime: "00:54:20"
  },
  {
    _id: 106,
    runner1: "Mia Sandblom",
    runner2: "Madelene Larsson",
    race: "lång",
    runnersclass: "dam",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:27:01.793Z",
    officialtime: "00:59:49"
  },
  {
    _id: 107,
    runner1: "Sebastian Nydahl",
    runner2: "Tomas Levin",
    race: "lång",
    runnersclass: "herr",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:17:31.023Z",
    officialtime: "00:50:18"
  },
  {
    _id: 108,
    runner1: "Cecilia Faktus",
    runner2: "Annaclara Faktus",
    race: "lång",
    runnersclass: "dam",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:19:00.316Z",
    officialtime: "00:51:48"
  },
  {
    _id: 109,
    runner1: "Emelie  Danielsson",
    runner2: "Sara Olsson",
    race: "lång",
    runnersclass: "dam",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:03:28.868Z",
    officialtime: "00:36:16"
  },
  {
    _id: 110,
    runner1: "Jakob Holmberg",
    runner2: "Peter Axeborn",
    race: "lång",
    runnersclass: "herr",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:00:05.088Z",
    officialtime: "00:32:52"
  },
  {
    _id: 111,
    runner1: "John Javér",
    runner2: "Elsa  Ljungquist",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:14:07.851Z",
    officialtime: "00:46:55"
  },
  {
    _id: 112,
    runner1: "Mattias Hägerstrand",
    runner2: "Ida Enstedt",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:03:14.785Z",
    officialtime: "00:36:02"
  },
  {
    _id: 113,
    runner1: "Isabella Sanchez",
    runner2: "Staffan  Palerius",
    race: "lång",
    runnersclass: "mixed",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:07:41.588Z",
    officialtime: "00:40:29"
  },
  {
    _id: 114,
    runnersclass: "herr",
    runner1: "Robert Heymann",
    runner2: "Martin Heymann",
    race: "lång",
    startgroup: "9",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:10:31.735Z",
    officialtime: "00:43:19"
  },
  {
    _id: 115,
    runnersclass: "mixed",
    runner1: "Nils Rene´",
    runner2: "Klara Törnblom",
    race: "lång",
    startgroup: "10",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:28:47.692Z",
    officialtime: "00:58:25",
    fisishtime: null
  },
  {
    _id: 116,
    runnersclass: "mixed",
    runner1: "Kristina Mjörnell",
    runner2: "Karl Norling",
    race: "lång",
    startgroup: "10",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:20:55.568Z",
    officialtime: "00:50:33",
    fisishtime: null
  },
  {
    _id: 117,
    runnersclass: "mixed",
    runner1: "Anna Petersson",
    runner2: "Andreas Johansson",
    race: "lång",
    startgroup: "10",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:21:24.539Z",
    officialtime: "00:51:02",
    fisishtime: null
  },
  {
    _id: 118,
    runnersclass: "dam",
    runner1: "Mia Engström",
    runner2: "Liselotte Henriksson",
    race: "lång",
    startgroup: "10",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:17:13.333Z",
    officialtime: "00:46:51",
    fisishtime: null
  },
  {
    _id: 119,
    runnersclass: "herr",
    runner1: "Erik Stenberg",
    runner2: "Filp Stenberg",
    race: "lång",
    startgroup: "10",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:10:02.567Z",
    officialtime: "00:39:40"
  },
  {
    _id: 120,
    runnersclass: "herr",
    runner1: "Christian Schriewer",
    runner2: "Levi Schriewer",
    race: "lång",
    startgroup: "10",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:29:33.566Z",
    officialtime: "00:59:11",
    fisishtime: null
  },
  {
    _id: 121,
    runnersclass: "dam",
    runner1: "Margret Schriewer",
    runner2: "Alma Schriewer",
    race: "lång",
    startgroup: "10",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:28:46.258Z",
    officialtime: "00:58:24"
  },
  {
    _id: 122,
    runnersclass: "mixed",
    runner1: "Emma Karlén",
    runner2: "Martin Svensson",
    race: "lång",
    startgroup: "10",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:27:11.191Z",
    officialtime: "00:56:49"
  },
  {
    _id: 131,
    runner1: "Zoe Bruce",
    runner2: "Daniel Bruce",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:24:28.190Z",
    officialtime: "00:24:08"
  },
  {
    _id: 132,
    runner1: "David Lundblad",
    runner2: "Elin Lundblad",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:19:48.268Z",
    officialtime: "00:19:28"
  },
  {
    _id: 133,
    runner1: "Mats Rydsbo",
    runner2: "Filip Rydsbo",
    race: "mellan",
    runnersclass: "herr",
    startgroup: 1,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 134,
    runner1: "Ella Rydsbo",
    runner2: "Lisa Rydsbo",
    race: "mellan",
    runnersclass: "dam",
    startgroup: 1,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 135,
    runner3: "Sofia Heymann",
    runner1: "Maya Kyllerman",
    runner2: "Sofia Heymann Olivia Krook",
    race: "mellan",
    runnersclass: "dam",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:20:42.743Z",
    officialtime: "00:20:23"
  },
  {
    _id: 136,
    runner1: "vilmarut granlund",
    runner2: "ingrid kyllerman",
    race: "mellan",
    runnersclass: "dam",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:21:37.117Z",
    officialtime: "00:21:17"
  },
  {
    _id: 137,
    runner1: "Elsa  Ljungqvist",
    runner2: "Jenny  Ljungqvist",
    race: "mellan",
    runnersclass: "dam",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:35:28.001Z",
    officialtime: "00:35:08"
  },
  {
    _id: 138,
    runner1: "Kalle Didrikson",
    runner2: "Tuva Lofgren",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:21:23.175Z",
    officialtime: "00:21:03"
  },
  {
    _id: 139,
    runner1: "Philip Sandgren",
    runner2: "Magnus  Sandgren",
    race: "mellan",
    runnersclass: "herr",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:21:58.525Z",
    officialtime: "00:21:39"
  },
  {
    _id: 140,
    runner1: "Ida Sandgren",
    runner2: "Catrin Sandgren",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:24:46.060Z",
    officialtime: "00:21:42"
  },
  {
    _id: 141,
    runner1: "Nils Herweijer",
    runner2: "Jessica Herweijer",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:24:35.280Z",
    officialtime: "00:21:31"
  },
  {
    _id: 142,
    runner1: "Ronja Kylén",
    runner2: "Ingrid Kylén",
    race: "mellan",
    runnersclass: "dam",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:24:00.939Z",
    officialtime: "00:20:57"
  },
  {
    _id: 143,
    runner1: "Miguel Van Tongeren",
    runner2: "Sophie Van Tongeren",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:26:15.345Z",
    officialtime: "00:23:11"
  },
  {
    _id: 144,
    runner1: "Olivia Cato",
    runner2: "Engla Fällén",
    race: "mellan",
    runnersclass: "dam",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:25:09.102Z",
    officialtime: "00:22:05"
  },
  {
    _id: 145,
    runner1: "Olle Fällén",
    runner2: "Johanna Fällén",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:26:45.249Z",
    officialtime: "00:23:41"
  },
  {
    _id: 146,
    runner1: "Valdemar Boman",
    runner2: "Love Johnard",
    race: "mellan",
    runnersclass: "herr",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:20:14.505Z",
    officialtime: "00:17:10"
  },
  {
    _id: 147,
    runner1: "Anna ohman",
    runner2: "Martina Harvey",
    race: "mellan",
    runnersclass: "dam",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:24:04.400Z",
    officialtime: "00:21:00"
  },
  {
    _id: 148,
    runner1: "Solveig Larsson",
    runner2: "Emil Reinholdsson",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:25:42.845Z",
    officialtime: "00:22:39"
  },
  {
    _id: 149,
    runner1: "Veronica Reinholdsson",
    runner2: "Noel Reinholdsson",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:27:45.908Z",
    officialtime: "00:21:35"
  },
  {
    _id: 150,
    runner1: "Mattias Sjostrand",
    runner2: "Erik Sjostrand",
    race: "mellan",
    runnersclass: "herr",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:25:59.791Z",
    officialtime: "00:19:49"
  },
  {
    _id: 151,
    runner1: "Emma Knutsson",
    runner2: "Alexandra Hall",
    race: "mellan",
    runnersclass: "dam",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:33:37.451Z",
    officialtime: "00:27:26"
  },
  {
    _id: 152,
    runner1: "Calle Rendahl ",
    runner2: "Pelle Rendahl",
    race: "mellan",
    runnersclass: "herr",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:27:33.230Z",
    officialtime: "00:21:22"
  },
  {
    _id: 153,
    runner1: "Noah Ljungkvist",
    runner2: "Åsa Leffler",
    race: "mellan",
    runnersclass: "mixed",
    startgroup: 3,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 154,
    runner1: "Emma  Claes",
    runner2: "Matilda  Persson",
    race: "mellan",
    runnersclass: "dam",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:27:47.068Z",
    officialtime: "00:21:36"
  },
  {
    _id: 155,
    runner1: "Vilmer Persson",
    runner2: "Filip Claes",
    race: "mellan",
    runnersclass: "herr",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:28:31.589Z",
    officialtime: "00:22:21"
  },
  {
    _id: 156,
    runner1: "Albin Hägerstrand",
    runner2: "Mattias Hägerstrand",
    race: "mellan",
    runnersclass: "herr",
    startgroup: 3,
    checkin: true,
    finished: false,
    finishtime: null,
    officialtime: null
  },
  {
    _id: 157,
    runnersclass: "herr",
    runner1: "Leo Bergman",
    runner2: "Benjamin Åberg",
    race: "mellan",
    startgroup: "3",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:24:13.542Z",
    officialtime: "00:18:03",
    fisishtime: null
  },
  {
    _id: 158,
    runnersclass: "herr",
    runner1: "Arvid Saviluoto",
    runner2: "Andreas Johanson",
    race: "mellan",
    startgroup: "1",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:55:51.904Z",
    officialtime: "00:55:32",
    fisishtime: null
  },
  {
    _id: 159,
    runnersclass: "mixed",
    runner1: "Teodor Eckerstein",
    runner2: "Susanna Eckerstein",
    race: "mellan",
    startgroup: "1",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:33:29.093Z",
    officialtime: "00:33:09"
  },
  {
    _id: 160,
    runnersclass: "mixed",
    runner1: "Annmarie Wesley",
    runner2: "Vilmer Wesley",
    race: "mellan",
    startgroup: "3",
    checkin: true,
    finished: true,
    finishtime: "2019-07-20T12:28:34.349Z",
    officialtime: "00:22:23",
    fisishtime: null
  },
  {
    _id: 229,
    runnersclass: "mixed",
    runner1: "Nils Rene´",
    runner2: "Klara Törnblom",
    race: "lång",
    startgroup: "1",
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null
  }
];

export default runners;
