import React from "react";
import styles from "./Social.module.css";
import { ReactComponent as Facebook } from "../assets/social/facebook_colored.svg";
import { ReactComponent as Instagram } from "../assets/social/instagram_colored.svg";

function Social(props) {
  return (
    <div className={styles.Social}>
      <a
        alt="facebook länk"
        className={styles.socialLink}
        href="https://www.facebook.com/StockenSOS/"
      >
        <Facebook className={styles.Icon} />
      </a>
      <a
        alt="Instagram länk"
        className={styles.socialLink}
        href="https://www.instagram.com/stockensos/"
      >
        <Instagram className={styles.Icon} />
      </a>
    </div>
  );
}

export default Social;
