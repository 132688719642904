const runners = [
  {
    _id: 7,
    runner1: "Melker Kyllerman",
    runner2: "Emil Storm",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:42:17.346Z",
    officialtime: "00:41:44",
    runnersclass: "herr",
  },
  {
    _id: 12,
    runner1: "Emma Mäsak",
    runner2: "Andrea Segerlind",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:48:14.411Z",
    officialtime: "00:46:12",
    runnersclass: "dam",
  },
  {
    _id: 22,
    runner1: "Tobias Westling",
    runner2: "Anna Krona Westling",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:47:14.560Z",
    officialtime: "00:43:29",
    runnersclass: "mixed",
  },
  {
    _id: 30,
    runner1: "Maria Rene",
    runner2: "Kristina Mjörnell",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:57:49.427Z",
    officialtime: "00:54:04",
    runnersclass: "dam",
  },
  {
    _id: 32,
    runner1: "Fredrik Asztely ",
    runner2: "Magnus Rene",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:09:27.235Z",
    officialtime: "01:04:02",
    runnersclass: "herr",
  },
  {
    _id: 225,
    runner1: "Kevin Tendahl",
    runner2: "Axel Klysing",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:27:30.014Z",
    officialtime: "00:27:14",
    runnersclass: "herr",
  },
  {
    _id: 37,
    runner1: "Theo Rendahl",
    runner2: "Alexander Borgquist",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:06.720Z",
    officialtime: "00:52:42",
    runnersclass: "herr",
  },
  {
    _id: 42,
    runner1: "Sara Karlsson",
    runner2: "Kristoffer Carlsson",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:10:18.600Z",
    officialtime: "01:03:10",
    runnersclass: "mixed",
  },
  {
    _id: 44,
    runner1: "Magnus Sandgren ",
    runner2: "Ida Sandgren ",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:54:21.507Z",
    officialtime: "00:47:13",
    runnersclass: "mixed",
  },
  {
    _id: 46,
    runner1: "Ameli Redkvist",
    runner2: "Oskar Redkvist ",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:11:09.004Z",
    officialtime: "01:04:00",
    runnersclass: "mixed",
  },
  {
    _id: 47,
    runner1: "Petra  Godhe",
    runner2: "Charlotta  Ling",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:01:10.458Z",
    officialtime: "00:54:02",
    runnersclass: "dam",
  },
  {
    _id: 50,
    runner1: "Linda  Hällström ",
    runner2: "Susanne Düning ",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:03:16.772Z",
    officialtime: "00:56:08",
    runnersclass: "dam",
  },
  {
    _id: 51,
    runner1: "Maria Bergqvist ",
    runner2: "Anna  Kärnevik Löf ",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:11:15.396Z",
    officialtime: "01:02:29",
    runnersclass: "dam",
  },
  {
    _id: 220,
    runner1: "Emelie Normand",
    runner2: "Amanda Normand",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:26:35.623Z",
    officialtime: "00:26:20",
    runnersclass: "dam",
  },
  {
    _id: 5,
    runner1: "Maria Enmark",
    runner2: "Anton  Enmark",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:57:42.937Z",
    officialtime: "00:57:09",
    runnersclass: "mixed",
  },
  {
    _id: 11,
    runner1: "Sonny Antonsson",
    runner2: "Andreas Granholm",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:48:11.334Z",
    officialtime: "00:46:09",
    runnersclass: "herr",
  },
  {
    _id: 222,
    runner1: "Niklas Carlén",
    runner2: "Ville Carlén",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:24:02.568Z",
    officialtime: "00:23:47",
    runnersclass: "herr",
  },
  {
    _id: 223,
    runner1: "Vera Addinsall",
    runner2: "Billie Björk Jortay",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:26:08.116Z",
    officialtime: "00:25:52",
    runnersclass: "dam",
  },
  {
    _id: 18,
    runner1: "Maria Lindborg",
    runner2: "Anders Lindborg",
    race: "lång",
    startgroup: 2,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 19,
    runner1: "Teresia Gerdén",
    runner2: "Cecilia Jondring Sarv",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:53.587Z",
    officialtime: "00:56:51",
    runnersclass: "dam",
  },
  {
    _id: 20,
    runner1: "Magnus Hellekant",
    runner2: "Beb Hellekant",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:01:59.783Z",
    officialtime: "00:59:58",
    runnersclass: "mixed",
  },
  {
    _id: 24,
    runner1: "Annie Crona Österström ",
    runner2: "Johan Crona",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:00:07.888Z",
    officialtime: "00:56:22",
    runnersclass: "mixed",
  },
  {
    _id: 26,
    runner1: "Michael Crona",
    runner2: "Gabriel Sturesson",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:41:54.127Z",
    officialtime: "00:38:09",
    runnersclass: "herr",
  },
  {
    _id: 27,
    runner1: "Robert Nygren",
    runner2: "Per Skogsberg",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:50:02.926Z",
    officialtime: "00:46:17",
    runnersclass: "herr",
  },
  {
    _id: 35,
    runner1: "Johanna  Skoglund ",
    runner2: "Lisa Wallin",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:50:15.037Z",
    officialtime: "00:44:50",
    runnersclass: "dam",
  },
  {
    _id: 230,
    runner1: "My  Yousofi",
    runner2: "Minoo Yousofi",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:37:00.840Z",
    officialtime: "00:34:36",
    runnersclass: "mixed",
  },
  {
    _id: 231,
    runner1: "Farasat Yousofi",
    runner2: "Arian Yousofi",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:30:03.349Z",
    officialtime: "00:27:38",
    runnersclass: "mixed",
  },
  {
    _id: 43,
    runner1: "Ken Hymnelius",
    runner2: "Jonny Tran",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:59.266Z",
    officialtime: "00:51:51",
    runnersclass: "herr",
  },
  {
    _id: 48,
    runner1: "Albin Henriksson",
    runner2: "Filip Henriksson ",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:50:30.320Z",
    officialtime: "00:43:22",
    runnersclass: "herr",
  },
  {
    _id: 2,
    runner1: "Mariella Bewick",
    runner2: "Olof Bewick",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:43:52.321Z",
    officialtime: "00:43:19",
    runnersclass: "mixed",
  },
  {
    _id: 4,
    runner1: "Viktor Grell",
    runner2: "Niklas Wittström",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:36:38.832Z",
    officialtime: "00:36:05",
    runnersclass: "herr",
  },
  {
    _id: 9,
    runner1: "Frida Wiigh",
    runner2: "Henrik Sjöman ",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:53:40.472Z",
    officialtime: "00:53:07",
    runnersclass: "mixed",
  },
  {
    _id: 10,
    runner1: "Jens Hjerling Leffler",
    runner2: "Ekaterina Leffler",
    race: "lång",
    startgroup: 1,
    checkin: false,
    finished: false,
    finishtime: "2023-07-14T16:23:03.345Z",
    officialtime: "",
    runnersclass: "mixed",
  },
  {
    _id: 221,
    runner1: "Sofie Widegren",
    runner2: "Miranda  Pettersson",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:26:44.710Z",
    officialtime: "00:26:29",
    runnersclass: "dam",
  },
  {
    _id: 14,
    runner1: "Karin Bäck",
    runner2: "John Bäck",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:49:45.213Z",
    officialtime: "00:47:43",
    runnersclass: "mixed",
  },
  {
    _id: 15,
    runner1: "Ida  Addinsall ",
    runner2: "Ulysse Björk Jortay",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:57:43.707Z",
    officialtime: "00:55:41",
    runnersclass: "mixed",
  },
  {
    _id: 16,
    runner1: "Niclas Karlsson",
    runner2: "Andreas Johansson",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:49:14.270Z",
    officialtime: "00:47:12",
    runnersclass: "herr",
  },
  {
    _id: 17,
    runner1: "Johan Söderlund",
    runner2: "David Eriksson",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:41:40.442Z",
    officialtime: "00:39:38",
    runnersclass: "herr",
  },
  {
    _id: 28,
    runner1: "Håkan  Dalemo",
    runner2: "Gustav Dalemo",
    race: "lång",
    startgroup: 3,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "herr",
  },
  {
    _id: 29,
    runner1: "Thøger Larsen",
    runner2: "Noel Massov",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:47:33.819Z",
    officialtime: "00:43:48",
    runnersclass: "herr",
  },
  {
    _id: 31,
    runner1: "Ulrika Hanse",
    runner2: "Gunilla Sonden",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:08:35.761Z",
    officialtime: "01:03:11",
    runnersclass: "dam",
  },
  {
    _id: 36,
    runner1: "Noel  Massot",
    runner2: "Oliver Massot",
    race: "lång",
    startgroup: 4,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "herr",
  },
  {
    _id: 226,
    runner1: "Vilma  Pilo",
    runner2: "Malin Pilo",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:30:54.855Z",
    officialtime: "00:30:39",
    runnersclass: "dam",
  },
  {
    _id: 228,
    runner1: "Micael Pilo",
    runner2: "Thomas Voeler",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:28:40.697Z",
    officialtime: "00:28:25",
    runnersclass: "herr",
  },
  {
    _id: 229,
    runner1: "Maria Drott  Johansson",
    runner2: "Susanne Persson",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:35:37.520Z",
    officialtime: "00:35:22",
    runnersclass: "dam",
  },
  {
    _id: 39,
    runner1: "Christine Florberger",
    runner2: "Wilhelm  Florberger",
    race: "lång",
    startgroup: 4,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 3,
    runner1: "Patrik Lundblad",
    runner2: "Tina Lundblad",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:48:59.165Z",
    officialtime: "00:48:25",
    runnersclass: "mixed",
  },
  {
    _id: 6,
    runner1: "Malin Storm",
    runner2: "Ella Storm",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:45:46.629Z",
    officialtime: "00:45:13",
    runnersclass: "dam",
  },
  {
    _id: 8,
    runner1: "Lisa  Sunnerek ",
    runner2: "Sofia Wendel ",
    race: "lång",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:55:33.764Z",
    officialtime: "00:55:00",
    runnersclass: "dam",
  },
  {
    _id: 13,
    runner1: "Roger Nyberg",
    runner2: "Frida Nyberg",
    race: "lång",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:55:39.072Z",
    officialtime: "00:53:37",
    runnersclass: "mixed",
  },
  {
    _id: 21,
    runner1: "Eva  Klockar",
    runner2: "Jessica Blume",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:56:50.896Z",
    officialtime: "00:53:05",
    runnersclass: "dam",
  },
  {
    _id: 23,
    runner1: "Liselott Ehrnberg",
    runner2: "Matts René ",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:34.357Z",
    officialtime: "00:54:49",
    runnersclass: "mixed",
  },
  {
    _id: 25,
    runner1: "Elis Malmeström",
    runner2: "Clas Malmeström",
    race: "lång",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:30.708Z",
    officialtime: "00:54:45",
    runnersclass: "herr",
  },
  {
    _id: 224,
    runner1: "Anna  Dalemo",
    runner2: "Vilhelm  Dalemo",
    race: "mellan",
    startgroup: 1,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 33,
    runner1: "Eric Hanse",
    runner2: "Göran Karestrand",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:55:24.466Z",
    officialtime: "00:49:59",
    runnersclass: "herr",
  },
  {
    _id: 34,
    runner1: "Richard  Hallgren",
    runner2: "Carl-Axel Hallgren",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:50:09.385Z",
    officialtime: "00:44:44",
    runnersclass: "herr",
  },
  {
    _id: 227,
    runner1: "Helena Bergström Pilo",
    runner2: "Michaela Voeler",
    race: "mellan",
    startgroup: 1,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:28:16.168Z",
    officialtime: "00:28:00",
    runnersclass: "dam",
  },
  {
    _id: 38,
    runner1: "Oscar Pilo",
    runner2: "Nils Österström",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:52:27.798Z",
    officialtime: "00:47:03",
    runnersclass: "herr",
  },
  {
    _id: 40,
    runner1: "Kristian Heikkinen",
    runner2: "Dick Andersson",
    race: "lång",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:47:54.119Z",
    officialtime: "00:42:29",
    runnersclass: "herr",
  },
  {
    _id: 41,
    runner1: "Martin Ivarson",
    runner2: "Arne Ivarson",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:03:37.237Z",
    officialtime: "00:56:29",
    runnersclass: "herr",
  },
  {
    _id: 45,
    runner1: "Catrin  Sandgren ",
    runner2: "Maria  Sandgren ",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:04:29.056Z",
    officialtime: "00:57:21",
    runnersclass: "dam",
  },
  {
    _id: 49,
    runner1: "John Lärke ",
    runner2: "Anneli  Berg",
    race: "lång",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:18:48.968Z",
    officialtime: "01:11:40",
    runnersclass: "mixed",
  },
  {
    _id: 52,
    runner1: "Axel Törnblom",
    runner2: "Philip Ericsson ",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:51:10.376Z",
    officialtime: "00:42:24",
    runnersclass: "herr",
  },
  {
    _id: 53,
    runner1: "Emilia Ström",
    runner2: "Marit Cuperus",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:50:53.614Z",
    officialtime: "00:42:07",
    runnersclass: "dam",
  },
  {
    _id: 54,
    runner1: "Linn Jönell",
    runner2: "Ingrid  Holmstjerna",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:56:14.691Z",
    officialtime: "00:47:28",
    runnersclass: "dam",
  },
  {
    _id: 55,
    runner1: "Patrik Petersson",
    runner2: "Anna Petersson",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:53:28.086Z",
    officialtime: "00:44:41",
    runnersclass: "mixed",
  },
  {
    _id: 56,
    runner1: "Markus Ekström",
    runner2: "Anna-Karin Relfsson",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:56:08.399Z",
    officialtime: "00:47:22",
    runnersclass: "mixed",
  },
  {
    _id: 57,
    runner1: "Ludvig  Högberg ",
    runner2: "Teo Eckerstein ",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:07:15.813Z",
    officialtime: "00:58:29",
    runnersclass: "herr",
  },
  {
    _id: 58,
    runner1: "Emil Eckerstein",
    runner2: "Samuel Eckerstein",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:10.087Z",
    officialtime: "00:49:23",
    runnersclass: "herr",
  },
  {
    _id: 59,
    runner1: "Liselott Henriksson",
    runner2: "Lise-Lotte Schroderus",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:21.206Z",
    officialtime: "00:49:34",
    runnersclass: "dam",
  },
  {
    _id: 60,
    runner1: "Mia Engström ",
    runner2: "Ellen Engström ",
    race: "lång",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:55:04.972Z",
    officialtime: "00:46:18",
    runnersclass: "dam",
  },
  {
    _id: 232,
    runner1: "Kenny Brovall",
    runner2: "Elton Brovall",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:19:01.924Z",
    officialtime: "00:16:37",
    runnersclass: "herr",
  },
  {
    _id: 61,
    runner1: "Matilda Grantz",
    runner2: "Tony Grantz",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:02.019Z",
    officialtime: "00:47:37",
    runnersclass: "mixed",
  },
  {
    _id: 62,
    runner1: "Ida Sjöberg",
    runner2: "Marcus Sjöberg",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:01:01.740Z",
    officialtime: "00:50:36",
    runnersclass: "mixed",
  },
  {
    _id: 63,
    runner1: "Wilgot Gustavsson",
    runner2: "Anton Bernhardsson",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:02:58.051Z",
    officialtime: "00:52:33",
    runnersclass: "herr",
  },
  {
    _id: 64,
    runner1: "Håkan Skutberg",
    runner2: "Maria Skutberg",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:02:27.399Z",
    officialtime: "00:52:02",
    runnersclass: "mixed",
  },
  {
    _id: 233,
    runner1: "Olivia  Skutberg",
    runner2: "Melvin Skutberg",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:22:35.366Z",
    officialtime: "00:20:10",
    runnersclass: "mixed",
  },
  {
    _id: 65,
    runner1: "Kristoffer Johnsson",
    runner2: "Lisa  Wessling ",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:10:10.588Z",
    officialtime: "00:59:45",
    runnersclass: "mixed",
  },
  {
    _id: 66,
    runner1: "Åsa Leffler",
    runner2: "Carl Högberg",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:59:49.982Z",
    officialtime: "00:49:25",
    runnersclass: "mixed",
  },
  {
    _id: 67,
    runner1: "Jenifer Ruz",
    runner2: "Susanne  Karjalainen ",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:09:38.592Z",
    officialtime: "00:59:13",
    runnersclass: "dam",
  },
  {
    _id: 234,
    runner1: "Magnus  Fischer",
    runner2: "Linnea Fischer",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:30:51.166Z",
    officialtime: "00:28:26",
    runnersclass: "mixed",
  },
  {
    _id: 235,
    runner1: "Jenny  Enlund ",
    runner2: "Gustav  Enlund ",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:26:19.004Z",
    officialtime: "00:23:54",
    runnersclass: "mixed",
  },
  {
    _id: 68,
    runner1: "Camilla schöneberger",
    runner2: "Henrik Persson",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:07:12.493Z",
    officialtime: "00:56:47",
    runnersclass: "mixed",
  },
  {
    _id: 236,
    runner1: "Mats Tullberg ",
    runner2: "Arvid Tullberg Sundholm",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:22:10.591Z",
    officialtime: "00:19:45",
    runnersclass: "herr",
  },
  {
    _id: 70,
    runner1: "Freja Freedman",
    runner2: "Viktor Wecke",
    race: "lång",
    startgroup: 7,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:52:18.568Z",
    officialtime: "00:41:53",
    runnersclass: "mixed",
  },
  {
    _id: 237,
    runner1: "Cissi Skoog",
    runner2: "Håkan  Persson",
    race: "mellan",
    startgroup: 2,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 71,
    runner1: "Ida Benkel",
    runner2: "Lina Benkel",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:06:28.178Z",
    officialtime: "00:54:23",
    runnersclass: "dam",
  },
  {
    _id: 72,
    runner1: "Martin Svahn Svahn ",
    runner2: "Anton  Svahn ",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:01:18.970Z",
    officialtime: "00:49:14",
    runnersclass: "herr",
  },
  {
    _id: 238,
    runner1: "Runa Joelsson",
    runner2: "Nils  Joelsson ",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:26:30.771Z",
    officialtime: "00:24:06",
    runnersclass: "mixed",
  },
  {
    _id: 73,
    runner1: "Filip Lindberg",
    runner2: "Filip Axell",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:53:34.167Z",
    officialtime: "00:41:29",
    runnersclass: "herr",
  },
  {
    _id: 74,
    runner1: "Henrik Eriksson",
    runner2: "Fabian Voeler",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:12:46.810Z",
    officialtime: "01:00:42",
    runnersclass: "herr",
  },
  {
    _id: 239,
    runner1: "Rymon Koda",
    runner2: "Jennie  Forslund ",
    race: "mellan",
    startgroup: 2,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:23:19.071Z",
    officialtime: "00:20:54",
    runnersclass: "mixed",
  },
  {
    _id: 240,
    runner1: "Peter Barratt-Due Goldberg",
    runner2: "Josef Larsson",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:26:20.891Z",
    officialtime: "00:21:56",
    runnersclass: "herr",
  },
  {
    _id: 241,
    runner1: "Alexander Markelov",
    runner2: "Dmitri Markelov",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:24:40.452Z",
    officialtime: "00:20:15",
    runnersclass: "herr",
  },
  {
    _id: 75,
    runner1: "Alice Olsson",
    runner2: "Leontien Herweijer",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:53:53.213Z",
    officialtime: "00:41:48",
    runnersclass: "dam",
  },
  {
    _id: 242,
    runner1: "Josefine Voeler",
    runner2: "Arvid Marklund",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:27:55.851Z",
    officialtime: "00:23:31",
    runnersclass: "mixed",
  },
  {
    _id: 243,
    runner1: "Vera  Goldberg",
    runner2: "Kalé Goldberg",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:26:28.039Z",
    officialtime: "00:22:03",
    runnersclass: "mixed",
  },
  {
    _id: 76,
    runner1: "Per Eriksson",
    runner2: "Annika Eriksson",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:55:11.200Z",
    officialtime: "00:43:06",
    runnersclass: "mixed",
  },
  {
    _id: 244,
    runner1: "Maja  Klysing",
    runner2: "Molly Persson",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:27:39.421Z",
    officialtime: "00:23:14",
    runnersclass: "dam",
  },
  {
    _id: 77,
    runner1: "Filip Claes",
    runner2: "Vilmer Persson",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:01.847Z",
    officialtime: "00:45:57",
    runnersclass: "herr",
  },
  {
    _id: 245,
    runner1: "Lena Fransson ",
    runner2: "Arvid  Fransson ",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:24:56.076Z",
    officialtime: "00:20:31",
    runnersclass: "mixed",
  },
  {
    _id: 246,
    runner1: "Maria Lembäck",
    runner2: "Julia Lembäck",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:24:27.522Z",
    officialtime: "00:20:02",
    runnersclass: "dam",
  },
  {
    _id: 247,
    runner1: "Olof  Larsson",
    runner2: "William  Lembäck",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:25:38.660Z",
    officialtime: "00:21:13",
    runnersclass: "herr",
  },
  {
    _id: 78,
    runner1: "Martin Thurehag",
    runner2: "Joakim Tuovinen",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:57:54.767Z",
    officialtime: "00:45:50",
    runnersclass: "herr",
  },
  {
    _id: 248,
    runner1: "Olle Eriksson",
    runner2: "Alessandro  Canonico",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:26:41.277Z",
    officialtime: "00:22:16",
    runnersclass: "herr",
  },
  {
    _id: 249,
    runner1: "Jon Birger Aarnes",
    runner2: "Jennie Aarnes",
    race: "mellan",
    startgroup: 3,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:33:54.775Z",
    officialtime: "00:29:30",
    runnersclass: "mixed",
  },
  {
    _id: 79,
    runner1: "Annsofi Öhman",
    runner2: "Hilda Axelsson",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:04:51.276Z",
    officialtime: "00:52:46",
    runnersclass: "dam",
  },
  {
    _id: 80,
    runner1: "Jenny Helmbro",
    runner2: "Therese Stomberg ",
    race: "lång",
    startgroup: 8,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:03:21.731Z",
    officialtime: "00:51:17",
    runnersclass: "dam",
  },
  {
    _id: 81,
    runner1: "Annika Danielsson ",
    runner2: "Emma  Hermansson ",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:24:31.861Z",
    officialtime: "01:10:45",
    runnersclass: "dam",
  },
  {
    _id: 82,
    runner1: "Jonatan Andersson",
    runner2: "David Andersson",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:51:23.928Z",
    officialtime: "00:37:37",
    runnersclass: "herr",
  },
  {
    _id: 83,
    runner1: "Olivia Krook",
    runner2: "Sofia Heymann",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:02:32.574Z",
    officialtime: "00:48:46",
    runnersclass: "dam",
  },
  {
    _id: 84,
    runner1: "Lennart Allinger",
    runner2: "Gunnar Schymberg",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:10:50.788Z",
    officialtime: "00:57:04",
    runnersclass: "herr",
  },
  {
    _id: 85,
    runner1: "Mikael  Hjörnhede",
    runner2: "Johan Helin",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:00:40.787Z",
    officialtime: "00:46:54",
    runnersclass: "herr",
  },
  {
    _id: 86,
    runner1: "Magnus Eriksson",
    runner2: "Lena  Eriksson",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:10:02.560Z",
    officialtime: "00:56:16",
    runnersclass: "mixed",
  },
  {
    _id: 87,
    runner1: "Kristofer Weidow",
    runner2: "Kristin Edström",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:11:58.120Z",
    officialtime: "00:58:11",
    runnersclass: "mixed",
  },
  {
    _id: 88,
    runner1: "Magnus Kellerman",
    runner2: "Gustav Kellerman",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:00:43.701Z",
    officialtime: "00:46:57",
    runnersclass: "herr",
  },
  {
    _id: 250,
    runner1: "Emma Olsson",
    runner2: "Maj Torge",
    race: "mellan",
    startgroup: 4,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "dam",
  },
  {
    _id: 89,
    runner1: "Andreas Larsson",
    runner2: "Tobias Jonsson ",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:53:03.261Z",
    officialtime: "00:39:17",
    runnersclass: "herr",
  },
  {
    _id: 90,
    runner1: "Kenny Broval",
    runner2: "Daniel Taube",
    race: "lång",
    startgroup: 9,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:49:39.711Z",
    officialtime: "00:35:53",
    runnersclass: "herr",
  },
  {
    _id: 91,
    runner1: "Madeleine Bjärne",
    runner2: "Jonas Sölsnaes",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 251,
    runner1: "Linnea Häggmark",
    runner2: "Lowe Häggmark",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:31:01.631Z",
    officialtime: "00:24:41",
    runnersclass: "mixed",
  },
  {
    _id: 92,
    runner1: "Karin Jones",
    runner2: "Mathias Håman",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: null,
    officialtime: "00:43:35",
    runnersclass: "mixed",
  },
  {
    _id: 93,
    runner1: "Josefin Röijer",
    runner2: "Eva Röijer",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:11:08.701Z",
    officialtime: "00:55:35",
    runnersclass: "dam",
  },
  {
    _id: 94,
    runner1: "Lena Tegström",
    runner2: "Pontus Tegström",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:00:10.226Z",
    officialtime: "00:44:37",
    runnersclass: "mixed",
  },
  {
    _id: 95,
    runner1: "Hampus Krook",
    runner2: "Alex Krook",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:51:00.920Z",
    officialtime: "00:35:27",
    runnersclass: "herr",
  },
  {
    _id: 96,
    runner1: "Edwin Nollier de Fiennes",
    runner2: "Robert  Buric",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:59:02.778Z",
    officialtime: "00:43:29",
    runnersclass: "herr",
  },
  {
    _id: 97,
    runner1: "Anton Hägg",
    runner2: "William Hägg",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:50:20.355Z",
    officialtime: "00:34:47",
    runnersclass: "herr",
  },
  {
    _id: 252,
    runner1: "Kristian Holmquist",
    runner2: "Hugo  Holmquist",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:28:06.704Z",
    officialtime: "00:21:46",
    runnersclass: "herr",
  },
  {
    _id: 98,
    runner1: "Elin Öggesjö ",
    runner2: "Emily Tillander",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:23:13.189Z",
    officialtime: "01:07:40",
    runnersclass: "dam",
  },
  {
    _id: 99,
    runner1: "Maria Hayden",
    runner2: "Markus  Hayden",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:40.063Z",
    officialtime: "00:43:07",
    runnersclass: "mixed",
  },
  {
    _id: 100,
    runner1: "Joakim Möller",
    runner2: "Maijana Urbany",
    race: "lång",
    startgroup: 10,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:05:46.629Z",
    officialtime: "00:50:13",
    runnersclass: "mixed",
  },
  {
    _id: 101,
    runner1: "Annica Gerber",
    runner2: "Marcus Dahlström",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:16:19.169Z",
    officialtime: "00:59:09",
    runnersclass: "mixed",
  },
  {
    _id: 102,
    runner1: "Amalia Gustafsson",
    runner2: "Erik Hjerpe",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:06:46.423Z",
    officialtime: "00:49:36",
    runnersclass: "mixed",
  },
  {
    _id: 103,
    runner1: "Gustav Dalemo",
    runner2: "Håkan Dalemo",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:04:28.199Z",
    officialtime: "00:47:18",
    runnersclass: "herr",
  },
  {
    _id: 104,
    runner1: "Simon Andersson",
    runner2: "Viktor Nyberg",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:04:19.046Z",
    officialtime: "00:47:09",
    runnersclass: "herr",
  },
  {
    _id: 105,
    runner1: "Jennifer Friman",
    runner2: "Wilda Friman",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:05:08.201Z",
    officialtime: "00:47:58",
    runnersclass: "dam",
  },
  {
    _id: 106,
    runner1: "Ines Rydehn",
    runner2: "Martin Rydehn",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:01:30.368Z",
    officialtime: "00:44:20",
    runnersclass: "mixed",
  },
  {
    _id: 107,
    runner1: "Guro Varvin Hjelseng",
    runner2: "Agnes Dahl",
    race: "lång",
    startgroup: 11,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "dam",
  },
  {
    _id: 253,
    runner1: "Olle Engström ",
    runner2: "Märta  Johansson ",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:25:16.404Z",
    officialtime: "00:18:56",
    runnersclass: "mixed",
  },
  {
    _id: 254,
    runner1: "Stina Kronberg",
    runner2: "Andreas Kronberg ",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:30:08.127Z",
    officialtime: "00:23:48",
    runnersclass: "mixed",
  },
  {
    _id: 108,
    runner1: "Sandra Lindqvist",
    runner2: "Mariel Samuelsson ",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:03:04.656Z",
    officialtime: "00:45:55",
    runnersclass: "dam",
  },
  {
    _id: 255,
    runner1: "Eva-Lena Aspelin",
    runner2: "Johan Aspelin",
    race: "mellan",
    startgroup: 4,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 256,
    runner1: "Ann-Kristin (Anki) Blomgren ",
    runner2: "Viktor  Blomgren ",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:30:31.995Z",
    officialtime: "00:24:12",
    runnersclass: "mixed",
  },
  {
    _id: 109,
    runner1: "Jennie Ohlsson ",
    runner2: "Eric Ohlsson ",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:57:39.715Z",
    officialtime: "00:40:30",
    runnersclass: "mixed",
  },
  {
    _id: 110,
    runner1: "Oscar Ohlsson",
    runner2: "Isac Ohlsson",
    race: "lång",
    startgroup: 11,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:02:10.479Z",
    officialtime: "00:45:00",
    runnersclass: "herr",
  },
  {
    _id: 111,
    runner1: "Elisabet Bellander",
    runner2: "Peter Persson ",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:00:16.130Z",
    officialtime: "00:41:31",
    runnersclass: "mixed",
  },
  {
    _id: 112,
    runner1: "Rebecca Gmoser",
    runner2: "Ulf Gmoser",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:02:13.599Z",
    officialtime: "00:43:29",
    runnersclass: "mixed",
  },
  {
    _id: 113,
    runner1: "Caspar Andrén",
    runner2: "Magnus Myhrborg",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:01:24.764Z",
    officialtime: "00:42:40",
    runnersclass: "herr",
  },
  {
    _id: 114,
    runner1: "Daniel  Pryde",
    runner2: "Charlie Bagge",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:16:42.316Z",
    officialtime: "00:57:58",
    runnersclass: "herr",
  },
  {
    _id: 257,
    runner1: "Vida Lindkvist",
    runner2: "Olle Lindkvist",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:30:15.354Z",
    officialtime: "00:23:55",
    runnersclass: "mixed",
  },
  {
    _id: 115,
    runner1: "Ola Båth-Dahlström",
    runner2: "Carlos Capo Milding",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:00:37.451Z",
    officialtime: "00:41:53",
    runnersclass: "herr",
  },
  {
    _id: 116,
    runner1: "Nils Ingvarson",
    runner2: "Niki Ingvarson",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:15:10.525Z",
    officialtime: "00:56:26",
    runnersclass: "mixed",
  },
  {
    _id: 258,
    runner1: "Lara Wehrmeyer",
    runner2: "John Martinsson",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:29:27.752Z",
    officialtime: "00:23:07",
    runnersclass: "mixed",
  },
  {
    _id: 117,
    runner1: "Sara Båth-Dahlström",
    runner2: "Johan Båth",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:27:02.722Z",
    officialtime: "01:08:18",
    runnersclass: "mixed",
  },
  {
    _id: 118,
    runner1: "Martin Hajen Andrén",
    runner2: "Daniel Delfinen Sunnerek",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:25:19.799Z",
    officialtime: "01:06:35",
    runnersclass: "herr",
  },
  {
    _id: 119,
    runner1: "Anna  Schlosser ",
    runner2: "Johan  Johansson ",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:55:36.262Z",
    officialtime: "00:36:52",
    runnersclass: "mixed",
  },
  {
    _id: 120,
    runner1: "Tilda Grell",
    runner2: "Maya Kyllerman",
    race: "lång",
    startgroup: 12,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:11:48.979Z",
    officialtime: "00:53:04",
    runnersclass: "dam",
  },
  {
    _id: 121,
    runner1: "Bo Johansson",
    runner2: "Johan Larsson",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:02:18.217Z",
    officialtime: "00:42:00",
    runnersclass: "herr",
  },
  {
    _id: 122,
    runner1: "Peter Axeborn",
    runner2: "Jesper Hassel",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:52:17.531Z",
    officialtime: "00:32:00",
    runnersclass: "herr",
  },
  {
    _id: 259,
    runner1: "Noah Ljungkvist",
    runner2: "Gustav Jägard ",
    race: "mellan",
    startgroup: 4,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:25:35.128Z",
    officialtime: "00:19:15",
    runnersclass: "herr",
  },
  {
    _id: 260,
    runner1: "Hildegard Granholm",
    runner2: "Stina Lindhe Hemming",
    race: "mellan",
    startgroup: 5,
    checkin: true,
    finished: false,
    finishtime: "2023-07-15T12:28:42.509Z",
    officialtime: "",
    runnersclass: "dam",
  },
  {
    _id: 261,
    runner1: "Richard Blom",
    runner2: "Torbjörn Sandgren",
    race: "mellan",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:28:32.308Z",
    officialtime: "00:19:57",
    runnersclass: "herr",
  },
  {
    _id: 262,
    runner1: "Maria Gutke Nigam",
    runner2: "Karin  Bremer ",
    race: "mellan",
    startgroup: 5,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "dam",
  },
  {
    _id: 123,
    runner1: "Daniel Bjärne",
    runner2: "William Lundin",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:11:33.487Z",
    officialtime: "00:51:15",
    runnersclass: "herr",
  },
  {
    _id: 124,
    runner1: "Staffan Nilson",
    runner2: "Alvar Nilson",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:08:08.780Z",
    officialtime: "00:47:51",
    runnersclass: "herr",
  },
  {
    _id: 125,
    runner1: "Carl Henrik Somp",
    runner2: "John Afzelius",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:01:15.657Z",
    officialtime: "00:40:58",
    runnersclass: "herr",
  },
  {
    _id: 263,
    runner1: "August Turndal Somp",
    runner2: "Ivar Afzelius",
    race: "mellan",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:27:27.924Z",
    officialtime: "00:18:53",
    runnersclass: "herr",
  },
  {
    _id: 264,
    runner1: "André Wilhelmsson",
    runner2: "Marie Wilhelmsson",
    race: "mellan",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:31:05.827Z",
    officialtime: "00:22:30",
    runnersclass: "mixed",
  },
  {
    _id: 126,
    runner1: "Carl Hultman",
    runner2: "Victoria  Örtenwall",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:04:34.585Z",
    officialtime: "00:44:17",
    runnersclass: "mixed",
  },
  {
    _id: 265,
    runner1: "Hugo Somp",
    runner2: "Sigge Söder",
    race: "mellan",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:31:00.694Z",
    officialtime: "00:22:25",
    runnersclass: "herr",
  },
  {
    _id: 127,
    runner1: "Alma  Lindkvist",
    runner2: "Teodor Berger",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:11:45.921Z",
    officialtime: "00:51:28",
    runnersclass: "mixed",
  },
  {
    _id: 128,
    runner1: "Hanna Niklasson",
    runner2: "Malin Tengelin ",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:06:41.239Z",
    officialtime: "00:46:23",
    runnersclass: "dam",
  },
  {
    _id: 266,
    runner1: "Ella Tengelin",
    runner2: "Marius Bråthen",
    race: "mellan",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:33:07.894Z",
    officialtime: "00:24:33",
    runnersclass: "mixed",
  },
  {
    _id: 129,
    runner1: "Ellen Wenström",
    runner2: "Axel Jakobsson",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:11:03.554Z",
    officialtime: "00:50:46",
    runnersclass: "mixed",
  },
  {
    _id: 130,
    runner1: "Anders Rosen",
    runner2: "Johan  Landgren",
    race: "lång",
    startgroup: 13,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:00:22.404Z",
    officialtime: "00:40:04",
    runnersclass: "herr",
  },
  {
    _id: 131,
    runner1: "Ulrik Henriksson",
    runner2: "Dan Bergman ",
    race: "lång",
    startgroup: 14,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T11:58:46.963Z",
    officialtime: "00:36:51",
    runnersclass: "herr",
  },
  {
    _id: 132,
    runner1: "Stefan  Pettersson ",
    runner2: "Kristoffer  Pipusch",
    race: "lång",
    startgroup: 14,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:12:32.612Z",
    officialtime: "00:50:36",
    runnersclass: "herr",
  },
  {
    _id: 267,
    runner1: "Vilgot Johnard",
    runner2: "Emma Ardbo Johnard",
    race: "mellan",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:27:57.598Z",
    officialtime: "00:19:22",
    runnersclass: "mixed",
  },
  {
    _id: 133,
    runner1: "Lotta  Fredman ",
    runner2: "Charlotta  Andersson",
    race: "lång",
    startgroup: 14,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:21:18.060Z",
    officialtime: "00:59:22",
    runnersclass: "dam",
  },
  {
    _id: 134,
    runner1: "Jonas Jonsson",
    runner2: "Johan Bååthe",
    race: "lång",
    startgroup: 14,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:07:29.596Z",
    officialtime: "00:45:33",
    runnersclass: "herr",
  },
  {
    _id: 268,
    runner1: "Daniel Taube",
    runner2: "Andrée Taube",
    race: "mellan",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:25:55.316Z",
    officialtime: "00:17:20",
    runnersclass: "herr",
  },
  {
    _id: 135,
    runner1: "Oscar Bååthe",
    runner2: "Alice Bååthe",
    race: "lång",
    startgroup: 14,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:06:02.816Z",
    officialtime: "00:44:07",
    runnersclass: "mixed",
  },
  {
    _id: 136,
    runner1: "Anna Sjöholm Tessin",
    runner2: "Marie Igelström",
    race: "lång",
    startgroup: 14,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:26:51.868Z",
    officialtime: "01:04:56",
    runnersclass: "dam",
  },
  {
    _id: 269,
    runner1: "Ted Thörner",
    runner2: "Hjalmar Thörner",
    race: "mellan",
    startgroup: 5,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:32:10.961Z",
    officialtime: "00:23:36",
    runnersclass: "herr",
  },
  {
    _id: 270,
    runner1: "Astrid Bergsten",
    runner2: "August Bergsten",
    race: "mellan",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:32:30.087Z",
    officialtime: "00:21:35",
    runnersclass: "mixed",
  },
  {
    _id: 137,
    runner1: "Maria  Pekar",
    runner2: "Ian Pekar",
    race: "lång",
    startgroup: 14,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:20:38.759Z",
    officialtime: "00:58:43",
    runnersclass: "mixed",
  },
  {
    _id: 271,
    runner1: "Svea Ahlenius",
    runner2: "Esther Kyllerman",
    race: "mellan",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:37:50.481Z",
    officialtime: "00:26:56",
    runnersclass: "dam",
  },
  {
    _id: 138,
    runner1: "Peter Johansson",
    runner2: "Tina Johansson",
    race: "lång",
    startgroup: 14,
    checkin: true,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "mixed",
  },
  {
    _id: 139,
    runner1: "Yvonne  Mårtensson",
    runner2: "Elinor Olausson",
    race: "lång",
    startgroup: 14,
    checkin: false,
    finished: false,
    finishtime: null,
    officialtime: null,
    runnersclass: "dam",
  },
  {
    _id: 140,
    runner1: "Anders Eliasson",
    runner2: "Tjomas Alvling",
    race: "lång",
    startgroup: 14,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:09:54.720Z",
    officialtime: "00:47:58",
    runnersclass: "herr",
  },
  {
    _id: 141,
    runner1: "henrik stråhle",
    runner2: "rasmus byman",
    race: "lång",
    startgroup: 15,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:22:30.269Z",
    officialtime: "00:58:49",
    runnersclass: "herr",
  },
  {
    _id: 142,
    runner1: "karl Hallgren ",
    runner2: "Gustav  Bergman",
    race: "lång",
    startgroup: 15,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:13:38.326Z",
    officialtime: "00:49:57",
    runnersclass: "herr",
  },
  {
    _id: 143,
    runner1: "Lisa Ödman",
    runner2: "Kristian Peders",
    race: "lång",
    startgroup: 15,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:18:21.576Z",
    officialtime: "00:54:40",
    runnersclass: "mixed",
  },
  {
    _id: 272,
    runner1: "Simon Leffler",
    runner2: "Wille Bäck",
    race: "mellan",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:34:36.187Z",
    officialtime: "00:23:41",
    runnersclass: "herr",
  },
  {
    _id: 144,
    runner1: "Markus Ransved Nilsson",
    runner2: "Gustav Spiik",
    race: "lång",
    startgroup: 15,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:08:36.217Z",
    officialtime: "00:44:55",
    runnersclass: "herr",
  },
  {
    _id: 145,
    runner1: "Frida Magnusson",
    runner2: "Sebastian Magnusson",
    race: "lång",
    startgroup: 15,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:23:25.199Z",
    officialtime: "00:59:44",
    runnersclass: "mixed",
  },
  {
    _id: 273,
    runner1: "Stina-Cajsa Berlin",
    runner2: "Ingrid  Berlin",
    race: "mellan",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:34:08.589Z",
    officialtime: "00:23:14",
    runnersclass: "dam",
  },
  {
    _id: 146,
    runner1: "Isabella  Sanchez",
    runner2: "Bror Johansson",
    race: "lång",
    startgroup: 15,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:08:07.981Z",
    officialtime: "00:44:27",
    runnersclass: "mixed",
  },
  {
    _id: 274,
    runner1: "Malou  Rendahl",
    runner2: "Calle Rendahl",
    race: "mellan",
    startgroup: 6,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:35:19.656Z",
    officialtime: "00:24:25",
    runnersclass: "mixed",
  },
  {
    _id: 147,
    runner1: "Loren  Smith",
    runner2: "Kajsa  Rydell ",
    race: "lång",
    startgroup: 15,
    checkin: true,
    finished: true,
    finishtime: "2023-07-15T12:13:40.173Z",
    officialtime: "00:49:59",
    runnersclass: "mixed",
  },
];
export default runners;
