import React, { useEffect, useState } from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { initGA, pageviewGA } from "./Components/GA";

import styles from "./App.module.css";
import Home from "./Components/Home/Home";
import Registration from "./Components/Registration/Registration";
import Result2019 from "./Components/Result/Result2019";
import Result2021 from "./Components/Result/Result2021";
import Result2022 from "./Components/Result/Result2022";
import Result2023 from "./Components/Result/Result2023";
import Result2024 from "./Components/Result/Result2024";
import Result2025 from "./Components/Result/Result2025";
import Andring from "./Components/Registration/Components/Andring";

//UA-156655216-1
import MenuStyles from "./App.css.js";

function App(props) {
  useEffect(() => {
    initGA();
    pageviewGA();
  }, []);

  const [viewResult, setViewResult] = useState(false);

  useEffect(() => {
    let today = new Date();
    let raceFinished = new Date("Sat Jul 19 2025 15:00:00 GMT+0200");
    if (today > raceFinished) {
      setViewResult(true);
    }
  }, []);

  return (
    <React.Fragment>
      <Menu styles={MenuStyles}>
        <NavLink to="/" className={styles.HeaderButton}>
          Hem
        </NavLink>
        {viewResult && (
          <NavLink to="/result2025" className={styles.HeaderButton}>
            Resultat 2025
          </NavLink>
        )}
        
        
          <NavLink to="/result2024" className={styles.HeaderButton}>
            Resultat 2024
          </NavLink>
        
        
          <NavLink to="/result2023" className={styles.HeaderButton}>
            Resultat 2023
          </NavLink>
        
        <NavLink to="/result2022" className={styles.HeaderButton}>
          Resultat 2022
        </NavLink>

        <NavLink to="/result2021" className={styles.HeaderButton}>
          Resultat 2021
        </NavLink>
        <NavLink to="/result2019" className={styles.HeaderButton}>
          Resultat 2019
        </NavLink>
        <NavLink to="/registration" className={styles.HeaderButton}>
          Anmälan
        </NavLink>
        
        <a className={styles.HeaderButton} href="/Banguide_Stocken_SOS_2024.pdf" download>Banguide</a>
      </Menu>
      <Switch>

      {viewResult && (
          <Route path="/result2025">
            <Result2025 />
          </Route>
        )}
      
          <Route path="/result2024">
            <Result2024 />
          </Route>
        
        
          <Route path="/result2023">
            <Result2023 />
          </Route>
        

        <Route path="/result2022">
          <Result2022 />
        </Route>

        <Route path="/result2021">
          <Result2021 />
        </Route>
        <Route path="/result2019">
          <Result2019 />
        </Route>
        <Route path="/registration">
          <Registration />
        </Route>
        <Route path="/andring/:id">
          <Andring />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </React.Fragment>
  );
}

export default App;
